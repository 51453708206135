// @flow
import React, { useEffect, useMemo } from 'react';
import {useMuiGridServerSide} from "../../../../hooks/useMuiGridServerSide";
import {DataGridPremium, GRID_AGGREGATION_ROOT_FOOTER_ROW_ID, GridActionsCellItem} from '@mui/x-data-grid-premium';
import {useTranslation} from "react-i18next";
import {LOANS} from "../../locales/namespaces";
import {
	Card,
	CardContent,
	Tooltip,
	Typography,
	Box,
	Button,
	Divider,
	IconButton, useMediaQuery
} from "@mui/material";
import {Link} from 'react-router-dom';
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOANS_DETAILS} from "../../routes/routes";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import PermissionHOC, {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import { ROUTE_PAGE_HOME, ROUTE_PAGE_WIZARD } from "../../../../routers/routes";
import useUserData from "../../../../hooks/user/useUserData";
import AlertMessageForSimpleUser
	from "../../../customers/components/alertMessageForSimpleUser/alertMessageForSimpleUser";
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment";
import IndexListLoans from "../../components/indexList/indexListLoans";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {CustomPagination} from "../../../../components/dataGridCustom/customPagination";
import {textTranslate} from "../../../../helpers/helpers";
import {mapLoan} from "../../types/map";
import {NumericFormat} from "react-number-format";
import useLoanStatus from "../../../parameters/hooks/useLoanStatus";
import useLoanType from "../../../parameters/hooks/useLoanType";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";

const Index = () => {
	const {t} = useTranslation(LOANS)
	const url = `loan/indexTable/data`
	const {language, thousandSeparator, decimalSeparator} = useUserData()
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Mortgages loans')
	}, [t])
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		initialSortModel: [{name: 'id', direction: 'descending'}],
		mapFunction: mapLoan
	})
	
	const {loanStatus} = useLoanStatus()
	const {loanType} = useLoanType()
	
	const columns = useMemo(() => [
		{
			field: 'actions',
			headerName: t('Actions'),
			type: 'actions',
			getActions: (params) => {
				if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
					return [];
				}
				return [
					<GridActionsCellItem
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_LOANS_DETAILS, {id: params.id})}
						icon={
							<Tooltip title={t('View')}>
								<IconButton>
									<VisibilityIcon/>
								</IconButton>
							</Tooltip>
						}
						label={t("Details")}>
					</GridActionsCellItem>
				]
			}
		},
		{
			field: 'StatusId',
			headerName: t('Loan status'),
			maxWidth: 200, flex: 1,
			type: 'singleSelect',
			valueOptions: loanStatus.map(loanStatus => ({
				value: loanStatus.id,
				label: textTranslate(language)({en: loanStatus.DescriptionEn, el: loanStatus.DescriptionEl}),
			})),
		},
		{
			field: 'Customer',
			headerName: t('Name'),
			maxWidth: 300, flex: 1,
		},
		{
			field: 'PhoneNumber',
			headerName: t('Phone'),
			maxWidth: 150, flex: 1,
		},
		{
			field: 'Email',
			headerName: t('Email'),
			maxWidth: 250, flex: 1,
		},
		{
			field: 'TypeId',
			headerName: t('Type'),
			maxWidth: 150, flex: 1,
			type: 'singleSelect',
			valueOptions: loanType.map(type => ({
				value: type.id,
				label: textTranslate(language)({en: type.DescriptionEn, el: type.DescriptionEl}),
			})),
		},
		{
			field: 'RequestedAmount',
			headerName: t('Requested Amount'),
			maxWidth: 150, flex: 1,
			type: 'number',
			renderCell: ({row}) => {
				return row.RequestedAmount ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.RequestedAmount}
				/> : 0
			}
		},
		{
			field: 'ApprovedAmount',
			headerName: t('Approved amount (€)'),
			maxWidth: 150, flex: 1,
			type: 'number',
			renderCell: ({row}) => {
				return row.ApprovedAmount ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.ApprovedAmount}
				/> : 0
			}
		},
		{
			field: 'RealEstateValue',
			headerName: t('Property Commercial Value (€)'),
			maxWidth: 180, flex: 1, type: 'number',
			renderCell: ({row}) => {
				return row.RealEstateValue ? <NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					displayType="text"
					value={row.RealEstateValue}
				/> : 0
			}
		},
		{
			field: 'CreatedAt',
			headerName: t('Created'),
			maxWidth: 100, flex: 1, type: 'date',
			valueFormatter: params =>
				moment(params?.value).format("DD-MM-YYYY"),
			aggregable: false,
		},
	
	], [data, refreshGrid])
	
	return (
		<Box>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
					<Breadcrumb.Item>
						{t('Loans')}
					</Breadcrumb.Item>
				</Breadcrumb>
			}
			<MainCard
				sx={{mt: 2}}
				title={
					<Typography variant="h5">
						{t('Mortgages loans')}
					</Typography>
				}
				secondary={
					<PermissionHOC requiredPermissions={['access_loan_create']}>
						<Button
							size={"small"}
							variant={"contained"}
							component={Link}
							color={'info'}
							to={getRouteUrl(ROUTE_PAGE_WIZARD)}
						>
							{t('Loan Request')}
						</Button>
					</PermissionHOC>
				}
			>
				<Divider sx={{mb: 2}} light/>
				<AlertMessageForSimpleUser/>
				{/*{data.length <= 0 &&
                    <PermissionHOC requiredPermissions={['access_loan_index_list', 'access_loan_index_data']}>
                        <Card>
                            <CardContent>
                                <Typography
                                    style={{textAlign: 'center'}}>{t('No loan request has been registered yet.')}</Typography>
                            </CardContent>
                        </Card>
                    </PermissionHOC>
                }
                {data.length > 0 &&*/}
				<PermissionHOC requiredPermissions={['access_loan_index_data']}>
					<DataGridWrapper>
						<DataGridPremium
							sx={{
								bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
									display: "none"
								},
								maxHeight: 650
							}}
							columns={columns}
							density={'compact'}
							rows={data}
							pagination
							paginationModel={{page, pageSize}}
							paginationMode='server'
							onPaginationModelChange={onPaginationModelChange}
							pageSizeOptions={[5, 10, 15, 20, 50, 100]}
							rowCount={Number(total)}
							sortingMode="server"
							onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
							filterMode='server'
							onFilterModelChange={onFilterModelChange}
							loading={isLoading}
							localeText={muiGridLocales(t)}
							slots={{
								pagination: CustomPagination,
							}}
							disableAggregation={true}
							disableRowGrouping={true}
						/>
					</DataGridWrapper>
				</PermissionHOC>
				{data.length > 0 ?
					<PermissionHOC requiredPermissions={['access_loan_index_list']}>
						<Box>
							<IndexListLoans data={data}/>
						</Box>
					</PermissionHOC>
					:
					<PermissionHOC requiredPermissions={['access_loan_index_list']}>
						<Card>
							<CardContent>
								<Typography
									style={{textAlign: 'center'}}>{t('No loan request has been registered yet.')}</Typography>
							</CardContent>
						</Card>
					</PermissionHOC>
					
				}
			</MainCard>
		</Box>
	);
};

export default withPermissionHOC(Index, ['access_loan_index'])