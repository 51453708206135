import React from "react";
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import { visuallyHidden } from '@mui/utils';

import { useWizard } from "../WizardContext";
import { useTranslation } from "react-i18next";
import { WIZARD } from "../../../locales/namespaces";
import useUserData from "../../../../hooks/user/useUserData";
import { intlNumber } from "../../../utils";

const LoanSummary = () => {
  const { t } = useTranslation(WIZARD);
  const { responses } = useWizard();
  const { language } = useUserData();

  const loanAmount = responses["10"].AnswerDescription;
  const propertyValue = responses["3"].AnswerDescription;
  const ltv = Math.round((loanAmount / propertyValue) * 100);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        border: "1px solid #dddee1",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        boxShadow: "0px 2.94px 23.49px 0px #3D467026",
      }}
    >
      <Stack
        spacing={1}
        sx={{
          flex: 1.4,
          alignItems: "center",
          p: { xs: "49px 16px", md: "49px 24px" },
        }}
      >
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.49411 18.3266V15.5072C6.4941 14.7901 7.05436 14.2074 7.74852 14.2026H10.2912C10.9887 14.2026 11.5541 14.7867 11.5541 15.5072V15.5072V18.3353C11.554 18.9442 12.0244 19.4418 12.6136 19.456H14.3087C15.9986 19.456 17.3685 18.0409 17.3685 16.2952V16.2952V8.27494C17.3595 7.58819 17.0473 6.94329 16.5209 6.52378L10.7235 1.69936C9.70786 0.859329 8.26409 0.859329 7.24845 1.69936L1.47648 6.53254C0.948067 6.95035 0.635414 7.59631 0.628906 8.28369V16.2952C0.628906 18.0409 1.9988 19.456 3.68865 19.456H5.38379C5.98764 19.456 6.47716 18.9504 6.47716 18.3266V18.3266" stroke="#0BC1B6" strokeWidth="1.23" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <Typography sx={{ textAlign: "center", color: "#8F96A9" }}>
          {t("Requested Loan Amount")}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "30px", sm: "35px" },
            lineHeight: "46px",
          }}
        >
          {intlNumber(loanAmount, language)}
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "row", md: "column" }}
        sx={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          p: 3,
          borderTop: { xs: "1px solid #D3D5DF", md: 0 }
        }}
      >
        <Typography sx={{ textAlign: { xs: "left", sm: "center" } }}>
          {t("Loan-to-Value Ratio for Property Value")}:
          <br />
          <strong>{intlNumber(propertyValue, language)}</strong>
        </Typography>
        <Typography
          sx={{
            flexShrink: 0,
            position: "relative",
            width: "90px",
            height: "90px",
            marginTop: 2,
            borderRadius: "50%",
            background: `conic-gradient(#0FBA2B ${ltv * 3.6}deg, #F6F8FC 0deg)`,
            fontSize: "24px",
            lineHeight: "30px",
            "&::before": {
              content: `"${ltv}%"`,
              position: "absolute",
              inset: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              borderRadius: "50%",
            }
          }}
        >
          <span style={visuallyHidden}>{ltv}%</span>
        </Typography>
      </Stack>
    </Stack>
  );
}

export default LoanSummary;
