import { useEffect, useRef, useState } from 'react';
// material-ui
import {
	Box,
	ButtonBase,
	ClickAwayListener,
	Paper,
	Popper,
	Stack,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

// project import
import ProfileTab from './profileTab';

// assets
import useUserData from "../../../../../../../hooks/user/useUserData";
import useUserAction from "../../../../../../../hooks/user/useUserAction";
import Transitions from "../../../../../components/@extended/transitions";
import MainCard from "../../../../../components/mainCard";
import Avatar from "../../../../../components/@extended/avatar";
import ProfileIcon from "../../../../../../../assets/images/icons/profileIcon";
import PermissionHOC from "../../../../../../../modules/permission/components/permissionHOC/permissionHOC";

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({ isLanding }) => {
	const theme = useTheme();
	const { loginName } = useUserData()
	const { userLogout } = useUserAction()
	
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};
	
	//const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
	const downSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const checkHover = ((e) => {
		if (anchorRef.current) {
			const mouseOver = anchorRef.current.contains(e.target);
			if (!isHovered && mouseOver) {
				setIsHovered(true)
			}
			
			if (isHovered && !mouseOver) {
				setIsHovered(false)
			}
		}
	})
	useEffect(() => {
		window.addEventListener("mousemove", checkHover, true);
		return () => {
			window.removeEventListener("mousemove", checkHover, true);
		}
	}, [checkHover, isHovered])
	
	return (
		<Box sx={{ flexShrink: 0, ml: 0.75 }}>
			{isLanding ? (
				<ButtonBase
					onMouseEnter={(e) => {
						e.preventDefault();
						setIsHovered(true)
					}}
					onMouseLeave={(e) => {
						e.preventDefault();
						setIsHovered(false)
					}}
					aria-label="open profile"
					ref={anchorRef}
					aria-controls={open ? 'profile-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					sx={{
						backgroundColor: isLanding && !open ? '#fff' : isLanding && open ? '#FFFFFF26' : 'text.primary',
						border: isLanding && open && '1px solid #fff',
						borderRadius: '16px',
						width: '56px',
						height: '56px',
						'&:hover': {
							border: isLanding && '1px solid #fff',
							backgroundColor: isLanding && '#FFFFFF26'
						}, //for landing header
						
					}}
					color={'text.primary'}
				>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
						<ProfileIcon isLanding={isLanding} isHovered={isHovered} open={open}/>
					</Stack>
				</ButtonBase>
			) : (
				<ButtonBase
					aria-label="open profile"
					ref={anchorRef}
					aria-controls={open ? 'profile-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					<Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
						<Avatar
							sx={{
								backgroundColor: 'text.primary',
								borderRadius: '16px',
								width: '56px',
								height: '56px'
							}}
							color={'text.primary'}
						>
							<ProfileIcon/>
						</Avatar>
						{!downSM &&
							<PermissionHOC requiredPermissions={['view_login_name']}>
								<Typography variant="body">{loginName}</Typography>
							</PermissionHOC>
						}
					</Stack>
				</ButtonBase>
			)}
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 9]
							}
						}
					]
				}}
				sx={{
					position: 'absolute',
					display: 'block',
					zIndex: '1'
				}}
			>
				{({ TransitionProps }) => (
					<Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
						<Paper
							sx={{
								boxShadow: theme.customShadows.z1,
								width: 290,
								minWidth: 240,
								maxWidth: 290,
								[theme.breakpoints.down('md')]: {
									maxWidth: 250
								}
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard elevation={0} border={false} content={false} sx={{
									p: 2
									// display: 'inline-flex',
									// flexDirection: 'column',
									// alignItems: 'flex-start',
									//gap: '16px'
								}}>
									{/* <CardContent sx={{px: 2.5, pt: 3}}>
                                        <Grid container justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <Stack direction="row" spacing={1.25} alignItems="center">
                                                    <Avatar alt="profile user" src={user}
                                                            sx={{width: 32, height: 32}}/>
                                                    <Stack>
                                                        <PermissionHOC requiredPermissions={['view_login_name']}>
                                                            <Typography variant="h6">{loginName}</Typography>
                                                        </PermissionHOC>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Logout">
                                                    <IconButton size="large" sx={{color: 'text.primary'}}
                                                                onClick={userLogout}>
                                                        <LogoutOutlined/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </CardContent>*/}
									{/*{!Capacitor.isNativePlatform() && <ChangeThemeMode/>}*/}
									<ProfileTab handleClose={handleClose} isLanding={isLanding}
												handleLogout={userLogout}/>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
		</Box>
	);
};

export default Profile;
