import * as React from "react";

const MoreHorizontal = (props) => (
	<svg
		width={21}
		height={6}
		viewBox="0 0 21 6"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M18.138 4.035a1.31 1.31 0 1 0 0-2.621 1.31 1.31 0 0 0 0 2.62m-7.862.001a1.31 1.31 0 1 0 0-2.621 1.31 1.31 0 0 0 0 2.62m-7.862.001a1.31 1.31 0 1 0 0-2.621 1.31 1.31 0 0 0 0 2.62"
			fill="#C1C6D1"
		/>
		<path
			d="M18.138 4.035a1.31 1.31 0 1 0 0-2.621 1.31 1.31 0 0 0 0 2.62m-7.862.001a1.31 1.31 0 1 0 0-2.621 1.31 1.31 0 0 0 0 2.62m-7.862.001a1.31 1.31 0 1 0 0-2.621 1.31 1.31 0 0 0 0 2.62"
			stroke="#C1C6D1"
			strokeWidth={1.966}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default MoreHorizontal;
