import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { WIZARD } from "../../../locales/namespaces";
import { useWizard } from "../WizardContext";
import useUserData from "../../../../hooks/user/useUserData";
import { intlNumber } from "../../../utils";

const MaxAmount = () => {
  const { t } = useTranslation(WIZARD);
  const { summary } = useWizard();
  const { language } = useUserData();

  const amount = intlNumber(summary.max_amount, language);

  return (
    <Stack
      spacing={2}
      sx={{
        p: 3,
        border: "1px solid #dddee1",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
      }}
    >
      <Typography sx={{ color: "#8F96A9" }}>
        {t("The maximum amount you can borrow based on the information you provided")}:
      </Typography>
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "30px",
        }}
      >
        {amount}
      </Typography>
    </Stack>
  );
}

export default MaxAmount;
