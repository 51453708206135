import * as React from "react";

export function UnsortedIcon() {
	return <SortIcon className="icon"/>
}

export const SortIcon = (props) => (
	<svg
		width={16}
		height={17}
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14 5.44H2a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h12c.273 0 .5.226.5.5 0 .273-.227.5-.5.5Zm-2 3.333H4a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h8c.273 0 .5.227.5.5s-.227.5-.5.5Zm-2.667 3.334H6.667a.504.504 0 0 1-.5-.5c0-.274.226-.5.5-.5h2.666c.274 0 .5.226.5.5 0 .273-.226.5-.5.5Z"
			fill="#485776"
		/>
	</svg>
)