import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

import { WIZARD } from "../../../locales/namespaces";
import { useWizard } from "../WizardContext";
import QuestionTitle from "../../elements/QuestionTitle";
import AmountField from "../../elements/AmountField";
import RadioOptions from "../../elements/RadioOptions";

const Finance = () => {
  const { t } = useTranslation(WIZARD);
  const { data, responses } = useWizard();

  return (
    <Stack spacing={8}>
      <div>
        <QuestionTitle
          title="What is your annual net income?"
          helperText={t("(Enter the total income of all borrowers if there is more than one)")}
          main
        />
        <AmountField itemId="13" />
      </div>
      <div>
        <QuestionTitle
          title="Do you have any other loan obligations?"
        />
        <RadioOptions
          itemId="14"
          options={data?.filter((data) => data.ItemId === "2")}
        />
      </div>
      {responses["14"]?.AnswerId === "6" && (
        <div>
          <QuestionTitle
            title="Enter the total amount of monthly installments you are paying."
          />
          <AmountField itemId="15" />
        </div>
      )}
      <div>
        <QuestionTitle
          title="Do you have credit cards or open loans? If yes, what is their total credit limit?"
        />
        <AmountField itemId="16" />
      </div>
    </Stack>
  );
}

export default Finance;
