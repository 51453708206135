//@flow
import React, { createContext, useEffect, useState } from 'react'
import useQuestionnaires from "../../hooks/useQuestionnaires";
import Questions from "./questions";
import Choices from "./choices";
import {Grid, Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";

export const QuestionnaireContext = createContext({})

const Questionnaires = () => {
	const {t} = useTranslation(PARAMETERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Questionnaires')
	}, [t])
	
	const [data, setData] = useState([])
	const [dataChoices, setDataChoices] = useState([])
	
	const [questionId, setQuestionId] = useState(null)
	const [choiceId, setChoiceId] = useState(null)
	
	const [questionRowSelection, setQuestionRowSelection] = useState()
	const [choiceRowSelection, setChoiceRowSelection] = useState()
	
	const {
		refreshQuestion,
		refreshChoice,
		storeQuestion,
		storeChoice,
		updateQuestion,
		updateChoice,
		removeQuestion,
		removeChoice
	} = useQuestionnaires()
	
	return (
		<QuestionnaireContext.Provider
			value={{
				data,
				setData,
				refreshQuestion,
				questionId,
				setQuestionId,
				questionRowSelection,
				setQuestionRowSelection,
				storeQuestion,
				updateQuestion,
				removeQuestion,
				dataChoices,
				setDataChoices,
				refreshChoice,
				choiceId,
				setChoiceId,
				choiceRowSelection,
				setChoiceRowSelection,
				storeChoice,
				updateChoice,
				removeChoice
			}}
		>
			<MainCard
				title={
					<Typography variant="h5" gutterBottom>
						{t('Questionnaires')}
					</Typography>
				}
				sx={{mt: 2}}
			>
				<Grid container>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Questions/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Choices/>
					</Grid>
				</Grid>
			</MainCard>
		</QuestionnaireContext.Provider>
	)
}

export default Questionnaires