import * as React from "react";
const ProfileIcon = (props) => {
    const strokeColor = (props.isLanding && props.isHovered) || (props.isLanding && props.open) ? '#fff' : (props.isLanding ? '#485776' : '#fff');
    return(
        <svg
            width={16}
            height={20}
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                fillRule="evenodd"
                clipRule="evenodd"
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path
                    d="M7.985 13.346c-3.868 0-7.17.585-7.17 2.926 0 2.342 3.281 2.948 7.17 2.948 3.867 0 7.17-.586 7.17-2.927 0-2.34-3.281-2.947-7.17-2.947Z"
                    strokeWidth={1.5}
                />
                <path
                    d="M7.985 10.006A4.596 4.596 0 1 0 3.389 5.41a4.58 4.58 0 0 0 4.564 4.596h.032Z"
                    strokeWidth={1.429}
                />
            </g>
        </svg>
    );
}
export default ProfileIcon;