// @flow
import React, {useState} from 'react';
import PermissionHOC from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {useTranslation} from "react-i18next";
import {
	Box,
	Button,
	Typography,
	Card,
	CardContent,
	useMediaQuery,
	Accordion,
	AccordionSummary,
	Grid,
	AccordionDetails
} from '@mui/material';
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import {ROUTE_PAGE_CALCULATIONS_CREATE} from "../../../modules/calculations/routes/routes";
import IndexListCalculations from "../../../modules/calculations/components/indexList/indexList";
import IndexListLoans from "../../../modules/loans/components/indexList/indexListLoans";
import {useMuiGridServerSide} from "../../../hooks/useMuiGridServerSide";
//import MainCard from "../../../theme/mantis/components/mainCard";
import {mapLoan} from "../../../modules/loans/types/map";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { ROUTE_PAGE_HOME, ROUTE_PAGE_WIZARD } from "../../../routers/routes";
import HomeBreadcrumbBlue from "../../../assets/images/icons/homeBreadcrumbBlue";
import ArrowDown2 from "../../../assets/images/icons/arrowDown2";

const CustomerDashboard = () => {
	const {t} = useTranslation(PAGE_HOME)
	const url = `loan/indexTable/data`
	
	const {data} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		initialSortModel: [{name: 'id', direction: 'descending'}],
		mapFunction: mapLoan
	})
	
	const [expandedLoan, setExpandedLoan] = useState(true);
	const [expandedCalc, setExpandedCalc] = useState(true);
	
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	const handleAccordionChangeLoan = () => {
		setExpandedLoan(!expandedLoan);
	};
	
	const handleAccordionChangeCalc = () => {
		setExpandedCalc(!expandedCalc);
	};
	
	return (
		<Box>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumbBlue} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
				</Breadcrumb>
			}
			<Box sx={{mt: 2}}>
				{/*<MainCard
					title={
						<PermissionHOC requiredPermissions={['access_loan_index_list']}>
							<Typography variant="h5">
								{t('My loans')}
							</Typography>
						</PermissionHOC>
					}
					sx={{mt: 2}}
					secondary={
						<PermissionHOC requiredPermissions={['access_loan_create']}>
							<Button
								size={"small"}
								variant={"contained"}
								color={'info'}
								component={Link}
								to={getRouteUrl(ROUTE_PAGE_LOANS_CREATE)}
							>
								{t('Loan Request')}
							</Button>
						</PermissionHOC>
					}
				>*/}
				<Accordion
					expanded={expandedLoan}
					onChange={handleAccordionChangeLoan}
					square={true} sx={{borderRadius: '12px'}}
				>
					<AccordionSummary
						expandIcon={<ArrowDown2/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								{/*<PermissionHOC requiredPermissions={['access_loan_index_list']}>*/}
									<Typography variant={'h4'} fontWeight={400} component={'div'}>
										{t('My loans')}
									</Typography>
								{/*</PermissionHOC>*/}
							</Grid>
							{expandedLoan && <Grid item>
							{/*	<PermissionHOC requiredPermissions={['access_loan_create']}>*/}
									<Button
										size={"small"}
										variant={"contained"}
										color={'info'}
										component={Link}
										sx={{textAlign: 'center'}}
										to={getRouteUrl(ROUTE_PAGE_WIZARD)}
									>
										{t('Loan Request')}
									</Button>
								{/*</PermissionHOC>*/}
							</Grid>}
						</Grid>
					</AccordionSummary>
				
					<AccordionDetails sx={{p: 3}}>
						<IndexListLoans data={data}/>
						{data.length <= 0 &&
							<Box>
								<Card>
									<CardContent>
										<Typography
											style={{textAlign: 'center'}}>{t('No loan request has been registered yet.')}</Typography>
									</CardContent>
								</Card>
							
							</Box>
						}
					</AccordionDetails>
					
				</Accordion>
				{/*	</MainCard>*/}
			</Box>
			<Box sx={{mt: 2}}>
				{/*<MainCard
					title={
						<Typography variant="h5">
							{t('My calculations')}
						</Typography>
					}
					sx={{mt: 2}}
					secondary={
						<Button
							size={"small"}
							variant={"contained"}
							color={'info'}
							component={Link}
							to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_CREATE)}
						>
							{t('Add loan scenario')}
						</Button>
					}
				>*/}
				<Accordion
					expanded={expandedCalc}
					onChange={handleAccordionChangeCalc}
					square={true} sx={{borderRadius: '12px'}}
				>
					<AccordionSummary
						expandIcon={<ArrowDown2/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Typography variant={'h4'} fontWeight={400} component={'div'}>
									{t('My calculations')}
								</Typography>
							</Grid>
							{expandedCalc && <Grid item>
								<Button
									size={"small"}
									variant={"contained"}
									color={'info'}
									sx={{textAlign: 'center'}}
									component={Link}
									to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_CREATE)}
								>
									{t('Add loan scenario')}
								</Button>
							</Grid>}
						</Grid>
					</AccordionSummary>
					<AccordionDetails sx={{p: 3}}>
						<IndexListCalculations/>
					</AccordionDetails>
				</Accordion>
				{/*</MainCard>*/}
			</Box>
		</Box>
	)
}

export default CustomerDashboard