import React from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { LANDING } from "../../../locales/namespaces";
import ApplyOnlineButton from "../../elements/ApplyOnlineButton";
import heroImage from "../../../../assets/images/landing/hero.png";

import styles from "./hero.module.css";

const Hero = () => {
  const { t } = useTranslation(LANDING);

  return (
    <section>
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.content}>
            <header className={styles.header}>
              <div className="elfsight-app-d669d24a-87f9-4a4c-9a12-3706b73cee49" data-elfsight-badge data-elfsight-app-lazy />
              <h1 className={styles.title}>
                {t("You can now start your mortgage application online!")}
              </h1>
            </header>
            <div className={styles.footer}>
              <Typography sx={{ fontSize: "16px" }}>{t("IMS, the largest mortgage broker in Greece, guides you to secure your loan with speed and ease.")}</Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>{t("All IMS services are free of charge with no additional cost for the borrower.")}</Typography>
              <ApplyOnlineButton />
            </div>
            <img src={heroImage} width={540} height={676} alt="IMS" className={styles.image} />
          </div>
        </Container>
      </div>
      <svg viewBox="0 0 1440 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.deco} aria-hidden="true">
        <path d="M0 25.7968V0.133789H1440V24.086C1024.05 40.0541 179.265 96.4466 127.724 98.2715C63.2965 100.553 39.7736 103.115 20.3454 84.5845C0.380423 65.5424 0.229201 35 0 25.7968Z" fill="#F6F8FC" />
      </svg>
    </section>
  )
}

export default Hero;
