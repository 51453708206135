// @flow
import React from 'react';
import { withPermissionHOC } from "../../modules/permission/components/permissionHOC/permissionHOC";
import HeaderLanding from "../../thinkPlus/components/landing/HeaderLanding";
import Landing from "../../thinkPlus/landing";

type Props = {

};

const AuthorizedLanding = (props: Props) => {
	return (
		<>
			<HeaderLanding />
			<Landing/>
		</>
	);
};

export default withPermissionHOC(AuthorizedLanding, ['access_cust_dashboard','access_admin_dashboard'])