import React from 'react'
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { WIZARD } from "../../locales/namespaces";
import AppStoresButtons from '../elements/AppStoresButtons';

const items = [
  "Immediate credit assessment",
  "100% cost free support",
  "No commitment",
  "Zero brokerage fees",
];

const Sidebar = () => {
  const { t } = useTranslation(WIZARD);

  return (
    <Stack spacing={4}>
      <Box sx={{ p: 3, bgcolor: "#fff", borderRadius: 3 }}>
        <Typography sx={{ mb: 4, fontSize: "15px", fontWeight: "600" }}>
          {t("Why choose IMS")}
        </Typography>
        <Stack component="ul" spacing={2} sx={{
          margin: 0,
          padding: 0,
          listStyle: "none",
        }}
        >
          {items.map((item) => {
            return (
              <Box key={item} component="li" sx={{
                display: "flex",
                gap: "10px",
                fontSize: "13px",
              }}>
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.3468 1.28223H5.69731C3.0316 1.28223 1.36035 3.16966 1.36035 5.84066V13.048C1.36035 15.719 3.02366 17.6065 5.69731 17.6065H13.3459C16.0204 17.6065 17.6846 15.719 17.6846 13.048V5.84066C17.6846 3.16966 16.0204 1.28223 13.3468 1.28223Z" stroke="#0BC1B6" strokeWidth="1.32359" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.38086 9.4445L8.47566 11.5384L12.6635 7.35059" stroke="#0BC1B6" strokeWidth="1.32359" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>{t(item)}</span>
              </Box>
            )
          })}
        </Stack>
      </Box>
      <Box sx={{ p: 3, bgcolor: "#fff", borderRadius: 3 }}>
        <Typography sx={{ mb: 3, fontSize: "15px", fontWeight: "600" }}>
          {t("Download the app")}
        </Typography>
        <AppStoresButtons direction="column" small />
      </Box>
    </Stack>
  )
}

export default Sidebar
