import React from "react";

import QuestionTitle from "../../elements/QuestionTitle";
import SelectField from "../../elements/SelectField";
import { useWizard } from "../WizardContext";

const Discovery = () => {
  const { data } = useWizard();

  return (
    <>
      <QuestionTitle title="How did you hear about us?" main />
      <SelectField
        itemId="22"
        options={data?.filter((data) => data.ItemId === "22")}
      />
    </>
  );
}

export default Discovery;
