// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { useMuiGridServerSide } from "../../../../hooks/useMuiGridServerSide";
// import {DataGridPremium, GRID_AGGREGATION_ROOT_FOOTER_ROW_ID, GridActionsCellItem} from '@mui/x-data-grid-premium';
import { DataGridPremium, } from "@mui/x-data-grid-premium";
import { useTranslation } from "react-i18next";
import { CUSTOMERS } from "../../locales/namespaces";
import {
	Typography,
	Box,
	Button,
	Divider,
	useMediaQuery
} from "@mui/material";
import { Link } from 'react-router-dom';
import { getRouteUrl } from "../../../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_CUSTOMERS_CREATE,
	ROUTE_PAGE_CUSTOMERS_DETAILS,
} from "../../routes/routes";
import ExcelImport from "../../../../components/excelImport/excelImport";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import PermissionHOC, { withPermissionHOC } from "../../../permission/components/permissionHOC/permissionHOC";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import { ROUTE_PAGE_HOME } from "../../../../routers/routes";
import { useCustomerImport } from "../../hooks/useCustomerImport";
import { API, DocsDownloadTypes } from "../../../../helpers/constants";
import moment from "moment";
import { muiGridLocales } from "../../../../helpers/muiGridLocales";
import MainCard from "../../../../theme/mantis/components/mainCard";
import { CustomPagination } from '../../../../components/dataGridCustom/customPagination'
import { useHasPermission } from "../../../permission/hooks/useHasPermission";
import { AuthenticatedLink } from "../../../../components/authenticatedLink/authenticatedLink";
import useCustomerStatus from "../../hooks/useCustomerStatus";
import useLoanStatus from "../../../parameters/hooks/useLoanStatus";
import useLoanType from "../../../parameters/hooks/useLoanType";
import { textTranslate } from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import UserPlusIcon from "../../../../assets/images/icons/buttonsIcons/userPlusIcon";
import LoginIcon from "../../../../assets/images/icons/buttonsIcons/loginIcon";
import DownloadIcon from "../../../../assets/images/icons/buttonsIcons/downloadIcon";
// import AcUnitIcon from '@mui/icons-material/AcUnit';
import { UnsortedIcon } from "../../../../assets/images/icons/sortIcon";
import './index.css'
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";
import { Capacitor } from "@capacitor/core";
import CircleIcon from '@mui/icons-material/Circle';

const colorMap = {
	'1': '#0BC1B6',
	'2': '#F4A40B',
	'3': '#5C6781',
}

//const ODD_OPACITY = 0.2;

/*export function AcUnit() {
	return <AcUnitIcon className="icon"/>;
}*/

// const StripedDataGrid = styled(DataGridPremium)(({theme}) => ({
//     [`& .${gridClasses.row}.even`]: {
//         backgroundColor: 'red',
//         '&:hover, &.Mui-hovered': {
//             backgroundColor: 'green',
//             '@media (hover: none)': {
//                 backgroundColor: 'blue',
//             },
//         },
//         '&.Mui-selected': {
//             backgroundColor: 'red',
//             '&:hover, &.Mui-hovered': {
//                 backgroundColor: 'green',
//                 // Reset on touch devices, it doesn't add specificity
//                 '@media (hover: none)': {
//                     backgroundColor: 'blue',
//                 },
//             },
//         },
//     },
//     [`& .${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer}`]: {
//         visibility: "visible",
//         width: "auto"
//     }
// }));

const Index = () => {
	const { t } = useTranslation(CUSTOMERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Customers')
	}, [t])
	
	const url = `customer/indexTable/data`
	const [showFileUpload, setShowFileUpload] = useState(false)
	const {
		loading,
		formik,
		importStatus,
		onFileChange,
		handleImportStatus
	} = useCustomerImport()
	//const theme = useTheme();
	const { language } = useUserData()
	//const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
	//const isCustomer = useHasPermission(['access_customer_edit'], {shouldView: true})
	const isActiveCustView = useHasPermission(['view_cust_isactive'], { shouldView: true })
	const hasLoanCustView = useHasPermission(['view_cust_hasLoan'], { shouldView: true })
	const isCustomerOrigin = useHasPermission(['view_cust_originid'], { shouldView: true })
	const isCustomerStatus = useHasPermission(['view_cust_status'], { shouldView: true })
	const isLoanStatus = useHasPermission(['view_loan_status_from_cust'], { shouldView: true })
	const isLoanType = useHasPermission(['view_loan_type_from_cust'], { shouldView: true })
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	const mapFilterModel = model => {
		if (model.length > 0) {
			model[0].predicates = model[0].predicates.map(f => {
				
				if (f.field === 'hasLoan') return { ...f, value: f.value === 'true' ? 1 : 0 }
				return f
			})
			return model
		}
	}
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		initialSortModel: [{ name: 'CreatedAt', direction: 'descending' }]
	})
	
	const { custStatus } = useCustomerStatus()
	const { loanStatus } = useLoanStatus()
	const { loanType } = useLoanType()
	
	const onCloseExcelImport = () => {
		handleImportStatus(null)
		formik.resetForm()
		setShowFileUpload(false);
		refreshGrid()
	}
	
	const columns = useMemo(() => [
		/*...(isCustomer ? [{
				headerName: t('Edit'),
				field: 'actions',
				type: 'actions',
				minWidth: 90, maxWidth: 100, flex: 1,
				getActions: (params) => {
					if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
						return [];
					}
					return [
						<GridActionsCellItem
							component={Link}
							to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_EDIT, {id: params.id})}
							icon={<EditIcon/>}
							label={t("Edit")}>
						</GridActionsCellItem>
					]
				}
			}] : []
		),*/
		...(isActiveCustView ? [
			{
				field: 'isActive',
				headerName: t('Active'),
				minWidth: 70, maxWidth: 130, flex: 1,
				type: 'boolean',
				valueGetter: ({ value }) => value === '1'
			}] : []),
		...(hasLoanCustView ? [
			{
				field: 'hasLoan',
				headerName: t('With request'),
				minWidth: 110, maxWidth: 130, flex: 1,
				type: 'boolean',
				valueGetter: ({ value }) => value === '1'
			}] : []),
		{
			field: 'CustName',
			headerName: t('Full name'),
			minWidth: 250, flex: 1,
			renderCell: (params) => {
				if (params.rowNode.type === 'group') {
					return params.value;
				}
				return (
					<Button
						sx={{
							color: '#485776',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 700,
							lineHeight: 'normal',
							'&:hover': {
								backgroundColor: 'rgba(244, 245, 250, 0.38)',
							},
						}}
						component={Link}
						variant={'info'}
						to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_DETAILS, {
							id: params.row.CustId ? params.row.CustId : params.row.id
						})}
					>
						{params.value}
					</Button>
				)
			},
		},
		{
			field: 'MobilePhone',
			headerName: t('Mobile Number'),
			minWidth: 120, flex: 1,
		},
		...(isCustomerStatus ? [{
			field: 'StatusId',
			headerName: t('Customer Status'),
			minWidth: 130, flex: 1,
			type: 'singleSelect',
			valueOptions: custStatus.map(custStatus => ({
				value: custStatus.id,
				label: custStatus.Description
			}))
		}] : []),
		...(isLoanStatus ? [{
			field: 'LoanStatusId',
			headerName: t('Loan Status'),
			minWidth: 130, flex: 1,
			type: 'singleSelect',
			valueOptions: loanStatus.map(loanStatus => ({
				value: loanStatus.id,
				label: textTranslate(language)({
					en: loanStatus.PartnerDescriptionEn,
					el: loanStatus.PartnerDescriptionEl
				}),
			})),
			renderCell: (params) => {
				const color = colorMap[params.row.ColorIndication]
				return (
					<span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
						{params.row.ColorIndication ? (
								<CircleIcon style={{ color: color, width: '1rem', height: '1rem' }}/>
							) :
							<div style={{ marginRight: '1rem' }}></div>}
						{params.formattedValue}
					</span>
				);
			}
			
		}] : []),
		...(isLoanType ? [{
			field: 'TypeId',
			headerName: t('Loan purpose'),
			minWidth: 130, flex: 1,
			type: 'singleSelect',
			valueOptions: loanType.map(type => ({
				value: type.id,
				label: textTranslate(language)({ en: type.DescriptionEn, el: type.DescriptionEl }),
			})),
		}] : []),
		{
			field: 'email',
			headerName: t('Email'),
			minWidth: 200, flex: 1,
		},
		{
			field: 'CreatedAt',
			headerName: t('Created'),
			minWidth: 110, flex: 1,
			type: 'date',
			valueFormatter: (params) => {
				return (params.value ? moment(params.value).format('DD-MM-YYYY') : null)
			}
		},
		...(isCustomerOrigin ? [
			{
				field: 'Origin',
				headerName: t('Origin'),
				minWidth: 100, flex: 1,
			}] : []),
		...(isCustomerOrigin ? [
			{
				field: 'PartnerName',
				headerName: t('Partner'),
				minWidth: 100, flex: 1,
			}] : []),
	], [custStatus, hasLoanCustView, isActiveCustView, isCustomerOrigin, isCustomerStatus, isLoanStatus, isLoanType, language, loanStatus, loanType, t])
	
	return (
		<Box className={'customer-index'}>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
					<Breadcrumb.Item>
						{t('Customers')}
					</Breadcrumb.Item>
				</Breadcrumb>
			}
			<MainCard
				sx={{ mt: 2 }}
				title={
					<Typography variant="h4">
						{t('Leads')}
					</Typography>
				}
				secondary={
					<>
						<PermissionHOC requiredPermissions={['view_cust_create_btn']}>
							<Button
								sx={{
									ml: 1,
									gap: '8px',
								}}
								size={"small"}
								variant={"contained"}
								color={'info'}
								component={Link}
								to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_CREATE)}
							>
								<UserPlusIcon/>{t('Add Customer')}
							</Button>
							<Button
								sx={{
									ml: 1,
									gap: '8px',
								}}
								size={"small"}
								variant={"outlined"}
								color={'info'}
								onClick={() => setShowFileUpload(true)}
							>
								<LoginIcon/>{t('Import from excel')}
							</Button>
						</PermissionHOC>
						<AuthenticatedLink
							url={`${API}/customer/export`}
							filename={t('Customer export') + '.xlsx'}
							disabled={!(data.length > 0)}
						>
							<Button
								sx={{
									ml: 1,
									gap: '8px',
								}}
								size={"small"}
								variant={"outlined"}
								color={'info'}
								component={"button"}
								target={!Capacitor.isNativePlatform() && "_blank"}
								disabled={!(data.length > 0)}
							>
								<DownloadIcon disabled={!(data.length > 0)}/>{t('Customer export')}
							</Button>
						</AuthenticatedLink>
					</>
				}
			>
				{/*<MainCard
                title={
                    <Toolbar disableGutters variant={"dense"}>
                        <Grid container justifyContent="space-between" alignItems="center"
                              sx={{position: 'relative', zIndex: 5}}>
                            <Grid item>
                                <Typography variant="h4">
                                    {t('Leads')}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sx={{mx: matchDownSM ? 2 : 3, my: matchDownSM ? 1 : 0, mb: matchDownSM ? 2 : 0}}
                                xs={matchDownSM ? 12 : 'auto'}
                            >
                                <PermissionHOC requiredPermissions={['view_cust_create_btn']}>
                                    <Button
                                        sx={{
                                            ml: 1,
                                            gap: '8px',
                                        }}
                                        size={"small"}
                                        variant={"contained"}
                                        color={'info'}
                                        component={Link}
                                        to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_CREATE)}
                                    >
                                        <UserPlusIcon/>{t('Add Customer')}
                                    </Button>
                                    <Button
                                        sx={{
                                            ml: 1,
                                            gap: '8px',
                                        }}
                                        size={"small"}
                                        variant={"outlined"}
                                        color={'info'}
                                        onClick={() => setShowFileUpload(true)}
                                    >
                                        <LoginIcon/>{t('Import from excel')}
                                    </Button>
                                </PermissionHOC>
                                <AuthenticatedLink
                                    url={`${API}/customer/export`}
                                    filename={t('Customer export')}
                                >
                                    <Button
                                        sx={{
                                            ml: 1,
                                            gap: '8px',
                                        }}
                                        size={"small"}
                                        variant={"outlined"}
                                        color={'info'}
                                        component={"button"}
                                        target={"_blank"}
                                    >
                                        <DownloadIcon/>{t('Customer export')}
                                    </Button>
                                </AuthenticatedLink>
                            </Grid>
                        </Grid>
                    </Toolbar>
                }
            >*/}
				<ExcelImport
					loading={loading}
					show={showFileUpload}
					onClose={onCloseExcelImport}
					formik={formik}
					templateUrl={`${API}/document/download?DocTypeId=${DocsDownloadTypes.CustomerExcel}`}
					importStatus={importStatus}
					handleImportStatus={handleImportStatus}
					onFileChange={onFileChange}
				>
					{/*  <ExcelImportTemplate/>*/}
				</ExcelImport>
				<Divider sx={{ mb: 2 }}/>
				{/*{data.length < 0 &&
                    <Box>
                        <Card>
                            <CardContent>
                                <Typography
                                    style={{textAlign: 'center'}}>{t('There are no registered customers.')}</Typography>
                            </CardContent>
                        </Card>
                    </Box>
                }
                {data.length > 0 &&*/}
				<DataGridWrapper>
					<DataGridPremium
						getRowClassName={(params) => {
							// console.log('params.indexRelativeToCurrentPage', params.indexRelativeToCurrentPage)
							// console.log('params.indexRelativeToCurrentPage % 2 === 0', params.indexRelativeToCurrentPage % 2 === 0)
							//console.log('params.indexRelativeToCurrentPage', params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')
							// debugger
							return (
								params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
							)
						}}
						sx={{
							"& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
								visibility: "visible",
								width: "auto"
							},
							"& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
								opacity: 0.5
							},
							".MuiDataGrid-aggregationColumnHeaderLabel": {
								display: "none"
							},
							maxHeight: 650,
						}}
						columns={columns}
						density={'compact'}
						rows={data}
						pagination
						paginationModel={{ page, pageSize }}
						paginationMode='server'
						onPaginationModelChange={onPaginationModelChange}
						rowCount={Number(total)}
						rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode='server'
						onFilterModelChange={(filterModel, details) => onFilterModelChange(filterModel, details, mapFilterModel)}
						// onFilterModelChange={onFilterModelChange}
						loading={isLoading}
						localeText={muiGridLocales(t)}
						slots={{
							pagination: CustomPagination,
							columnUnsortedIcon: UnsortedIcon,
							//columnMenuIcon: AcUnit
						}}
						disableAggregation={true}
						disableRowGrouping={true}
						/*getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'rgba(57,93,211,0.49)' : '#7c1b1b'
						}*/
						/* getRowClassName={(params) =>
							 params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						 }*/
					/>
				</DataGridWrapper>
			</MainCard>
		</Box>
	);
};

export default withPermissionHOC(Index, ['access_customer_index'])