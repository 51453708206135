import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, useMediaQuery } from '@mui/material';
import LogoIcon from "./logoIcon";
import LogoMain from "./logoMain";

// project import

// ==============================|| MAIN LOGO ||============================== //
const LogoSection = ({ reverse, drawer, sx, to }) => {
	const isMobile = useMediaQuery('(max-width: 800px)');
	
	return (
		<ButtonBase
			disableRipple
			component={Link}
			to={to}
			sx={sx}
			onClick={(event) => {
				event.stopPropagation()
			}}
		>
			{drawer ? (
				<LogoIcon/>
			) : (
				<LogoMain
					reverse={reverse}
					width={isMobile ? 145 : 145}
					height={isMobile ? 50 : 50}
				/>
			)}
		</ButtonBase>
	)
}

// LogoSection.propTypes = {
//   reverse: PropTypes.bool,
//   isIcon: PropTypes.bool,
//   sx: PropTypes.object,
//   to: PropTypes.string
// };

export default LogoSection;
