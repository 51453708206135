import React from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { LANDING } from "../../../locales/namespaces";
import mobileAppImage from "../../../../assets/images/landing/mobile-app.png"
import AppStoresButtons from "../../elements/AppStoresButtons";

import styles from "./mobileApp.module.css";

const items = [
  "Submitting a mortgage loan application",
  "Tracking the status of your application with updates at every stage",
  "Notifications and reminders for required actions",
  "Submitting supporting documents",
  "Loan installment calculator with detailed information, reminders, and repayment scheduling.",
];

const MobileApp = () => {
  const { t } = useTranslation(LANDING);

  return (
    <section className={styles.section}>
      <Container>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item sm={6} className={styles.column}>
            <img src={mobileAppImage} width={428} height={505} alt="IMS Mobile App" loading="lazy" className={styles.image} />
          </Grid>
          <Grid item sm={6} className={styles.column}>
            <div className={styles.content}>
              <h2 className={styles.title}>
                {t("Download the app on your mobile to stay updated on your loan application!")}
              </h2>
              <Stack component="ul" spacing={2} className={styles.list}>
                <li className={styles.item}>{t("The app provides you with all the necessary features, such as")}:</li>
                {items.map((item) => {
                  return (
                    <li key={item} className={styles.item}>
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.3468 1.28223H5.69731C3.0316 1.28223 1.36035 3.16966 1.36035 5.84066V13.048C1.36035 15.719 3.02366 17.6065 5.69731 17.6065H13.3459C16.0204 17.6065 17.6846 15.719 17.6846 13.048V5.84066C17.6846 3.16966 16.0204 1.28223 13.3468 1.28223Z" stroke="#0BC1B6" strokeWidth="1.32359" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.38086 9.4445L8.47566 11.5384L12.6635 7.35059" stroke="#0BC1B6" strokeWidth="1.32359" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <strong>{t(item)}</strong>
                    </li>
                  )
                })}
              </Stack>
              <AppStoresButtons />
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default MobileApp;
