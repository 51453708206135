import React from "react";

import SuccessMessage from "../../elements/SuccessMessage";
import WarningMessage from "../../elements/WarningMessage";
import ErrorMessage from "../../elements/ErrorMessage";
import { useWizard } from "../WizardContext";
import { resultMessageText } from "../../../utils";


const ResultMessage = () => {
  const { responses, summary } = useWizard();
  const message = resultMessageText({
    level: summary.approval_level,
    resultingPrefix: summary.max_amount < responses["10"].AnswerDescription
  });

  const LEVELS = {
    "0": <ErrorMessage message={message} />,
    "1": <ErrorMessage message={message} />,
    "2": <WarningMessage message={message} />,
    "3": <SuccessMessage message={message} />,
  }

  return LEVELS[summary.approval_level];
}

export default ResultMessage;
