// @flow
import React, { useEffect, useMemo } from 'react';
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import useCountries from "../../../users/hooks/useCountries";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {CustomPagination} from "../../../../components/dataGridCustom/customPagination";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import {GridEditInputCell} from "@mui/x-data-grid-premium";

const Countries = () => {
	const {t} = useTranslation(PARAMETERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Countries')
	}, [t])
	
	const {
		loading,
		countries,
		update
	} = useCountries()
	
	const newRowModel = {
		DescriptionEl: '',
		DescriptionEn: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'isDefault',
			headerName: t('Default'),
			minWidth: 150,
			flex: 1,
			maxWidth: 150,
			type: 'boolean',
			valueGetter: ({value}) => value === '1'
		},
		{
			field: 'Code',
			headerName: t('Code'),
			minWidth: 160,
			flex: 1,
			maxWidth: 160,
		},
		{
			field: 'Image',
			headerName: t('Flag'),
			maxWidth: 140,
			flex: 1,
			renderCell: (params) => {
				return (
					<img
						src={params.row.ImagePath}
						alt={params.row.DescriptionEn}
						style={{width: '35px'}}
					/>
				)
			},
		},
		{
			field: 'PhoneCode',
			headerName: t('Phone Code'),
			minWidth: 160,
			flex: 1,
			maxWidth: 160,
			editable: true,
		},
		{
			field: 'DescriptionEl',
			headerName: t('Description EL'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				const inputMinLength = params.props.value.length < 3;
				const inputMaxLength = params.props.value.length > 50;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				} else if (inputMinLength) {
					return {...params.props, error: t('Characters must be more than 3')};
				} else if (inputMaxLength) {
					return {...params.props, error: t('Length must be smaller than 50.')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'DescriptionEn',
			headerName: t('Description EN'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				const inputMinLength = params.props.value.length < 3;
				const inputMaxLength = params.props.value.length > 50;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				} else if (inputMinLength) {
					return {...params.props, error: t('Characters must be more than 3')};
				} else if (inputMaxLength) {
					return {...params.props, error: t('Length must be smaller than 50.')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
	], [countries, t])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Countries')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper
				autoHeight
			>
				<EditableMUIDataGrid
					sx={{maxHeight: 600}}
					loading={loading}
					data={countries}
					columns={columns}
					newRowModel={newRowModel}
					update={update}
					hideDeleteButton={true}
					dataGridProOtherProps={{
						disableColumnMenu: true,
						slots: {
							toolbar: false,
							pagination: CustomPagination,
						}
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
		/>
	)
}

export default withPermissionHOC(Countries, ['access_parameters'])