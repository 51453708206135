// @flow
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {USERS} from "../../locales/namespaces";
import {
    Dialog,
    DialogContent,
    Grid,
    Typography,
    Box,
    Toolbar,
    Divider,
    Button,
    useMediaQuery, DialogTitle
} from "@mui/material";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_USERS_INDEX} from "../../routes/routes";
import axios from 'axios'
import useUserData from "../../../../hooks/user/useUserData";
import {API} from "../../../../helpers/constants";
import RoleTable from "../../components/Tables/roles/roleTable";
import TeamsTable from "../../components/Tables/teams/usersTeamsTable";
import HistoryLoginTable from "../../components/Tables/historyLogin/historyLoginTable";
import PermissionHOC, {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import HomeIcon from '@mui/icons-material/Home';
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import useRoles from "../../hooks/useRoles";
import Profile from "../../components/profile/profile";
import ResetUserPasswordForm from "../../components/resetUserPasswordForm/resetUserPasswordForm";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import useUserAction from "../../../../hooks/user/useUserAction";
import MainCard from "../../../../theme/mantis/components/mainCard";
import {useTheme} from "@mui/material/styles";
import ContentLoader from "../../../../components/contentLoader/contentLoader";

const Details = () => {
    const {t} = useTranslation(USERS)
    
    useEffect(() => {
        document.title = t('IMS Financial Consulting | User Profile')
    }, [t])
    
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    const [userData, setUserData] = useState({})
    const [open, setOpen] = useState(false);
    const {token, isLoggedIn} = useUserData()
    const {userLogout} = useUserAction()
    const {roles} = useRoles()
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'post',
            data: {UserId: id},
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: `${API}/user/details/data`,
        }).then(response => {
            const data = response.data
            const user = data.user
            //const roles = data.roles
            setUserData({
                ...user,
                isActive: user.isActive,
                CustId: user.CustId
            })
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to get profile.')), {
                    variant: 'error'
                }
            )
            logoutUnauthorizedUser(error, isLoggedIn, userLogout)
        })
    }, [id])

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return !loading ? (
        <Box>
            <PermissionHOC requiredPermissions={['access_user_index']}>
                <Breadcrumb>
                    <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                        {t('Home Page')}
                    </Breadcrumb.Link>
                    <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_USERS_INDEX)}>
                        {t('Users')}
                    </Breadcrumb.Link>
                    <Breadcrumb.Item>
                        {t('User Profile')}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </PermissionHOC>
            <Toolbar disableGutters variant={"dense"}>
                <Grid container justifyContent="space-between" alignItems="center"
                      sx={{position: 'relative', zIndex: 5}}>
                    <Grid item>
                        {/*<Typography variant="h4" gutterBottom>
                            {t('Profile')}
                        </Typography>*/}
                    </Grid>
                    <Grid item sx={{mx: matchDownSM ? 2 : 3, my: matchDownSM ? 1 : 0, mb: matchDownSM ? 2 : 0}}
                          xs={matchDownSM ? 12 : 'auto'}>
                        <PermissionHOC
                            requiredPermissions={['view_usr_reset_passwd_button', 'edit_usr_reset_passwd_button']}>
                            <Button
                                size={"small"}
                                color={"blue"}
                                variant={"contained"}
                                onClick={handleOpen}
                            >
                                {t('Reset Password')}
                            </Button>
                        </PermissionHOC>
                    </Grid>
                </Grid>
            </Toolbar>
            <Divider sx={{mb: 2}} light/>
            <PermissionHOC requiredPermissions={['access_partner_profile']}>
                <Profile id={id} userData={userData}/>
            </PermissionHOC>
            <PermissionHOC requiredPermissions={['view_usr_roles']}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Profile id={id} userData={userData}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <MainCard sx={{mb: 2}} title={
                            <Toolbar disableGutters variant={"dense"}>
                                <Typography variant="h5" gutterBottom>{t('Roles')}</Typography>
                            </Toolbar>
                        }>
                            <RoleTable id={id} roles={roles}/>
                        </MainCard>
                    </Grid>
                </Grid>
            </PermissionHOC>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PermissionHOC requiredPermissions={['view_usr_teams']}>
                        <MainCard sx={{mb: 2}} title={
                            <Toolbar disableGutters variant={"dense"}>
                                <Typography variant="h5" gutterBottom>{t('Teams')}</Typography>
                            </Toolbar>
                        }>
                            <TeamsTable/>
                        </MainCard>
                    </PermissionHOC>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PermissionHOC requiredPermissions={['view_usr_log_history']}>
                        <MainCard sx={{mb: 2}} title={
                            <Toolbar disableGutters variant={"dense"}>
                                <Typography variant="h5" gutterBottom>{t('History Login')}</Typography>
                            </Toolbar>
                        }>
                            <HistoryLoginTable/>
                        </MainCard>
                    </PermissionHOC>
                </Grid>
            </Grid>
            <Divider light sx={{mt: 1}}/>
            <Dialog open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle>
                    <Typography variant="h4">{t("Reset Password")}</Typography>
                </DialogTitle>
                <DialogContent>
                    <ResetUserPasswordForm handleClose={handleClose}/>
                </DialogContent>
            </Dialog>
        </Box>
    ) : (
        <ContentLoader
            loading={loading}
        />
    )
}

export default withPermissionHOC(Details, ['access_user_details'])