// @flow
import React, { useEffect, useMemo } from 'react';
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import useNationalities from "../../../customers/hooks/useNationalities";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {DataGridPremium} from '@mui/x-data-grid-premium';
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";

const Nationalities = () => {
	const {t} = useTranslation(PARAMETERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Nationalities')
	}, [t])
	
	const {language} = useUserData()
	const {
		loading,
		nationalities
	} = useNationalities()
	
	const columns = useMemo(() => [
		{
			field: 'id',
			headerName: t('Description'),
			minWidth: 160,
			flex: 1,
			type: 'singleSelect',
			valueOptions: nationalities.map(nationalities => ({
				value: nationalities.id,
				label: textTranslate(language)({en: nationalities.DescriptionEn, el: nationalities.Description})
			})),
		},
	], [nationalities, t])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Nationalities')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper
				autoHeight
				sx={{
					maxWidth: '500px'
				}}
			>
				<DataGridPremium
					sx={{
						bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
							display: "none"
						},
						maxHeight: 700
					}}
					disableColumnMenu={true}
					columns={columns}
					density={'compact'}
					rows={nationalities}
					loading={loading}
					localeText={muiGridLocales(t)}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
		/>
	)
}

export default withPermissionHOC(Nationalities, ['access_parameters'])