import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import { useWizard } from "../wizard/WizardContext";

const AddressField = ({ itemId }) => {
  const { responses, updateResponse } = useWizard();

  return (
    <FormControl fullWidth>
      <TextField
        value={responses[itemId]?.AnswerDescription || ""}
        onChange={(e) => updateResponse(itemId, { AnswerDescription: e.target.value })}
        sx={{
          "& .MuiInputBase-root": {
            border: "1px solid #dddee1",
            borderRadius: "8px",
            background: "none",
          },
          "& .MuiInputBase-input": {
            p: 2.5,
            fontSize: "0.875rem",
            lineHeight: "1.57",
          },
        }}
      />
    </FormControl>
  );
}

export default AddressField;
