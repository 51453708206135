export const intlNumber = (amount = 0, lang = "el") => new Intl.NumberFormat(lang, {
  style: "currency",
  currency: "EUR",
  maximumFractionDigits: 0,
}).format(amount);

export const resultMessageText = ({ level, resultingPrefix = false }) => {
  const prefix = resultingPrefix ? "The loan amount resulting" : "The loan amount you have requested";
  const LEVELS = {
    "0": "Unfortunately, you cannot receive pre-approval for a mortgage loan",
    "1": "The loan amount you have requested is marginally viable, and the payment of the installment will put pressure on your family income",
    "2": `${prefix} is viable, but the payment of the installment will put some pressure on your family income`,
    "3": "The loan amount you have applied for is sustainable, and the installment payment will be made without significant pressure on your family income",
  }

  return LEVELS[level] || "";
};
