import React from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";

import { LANDING } from "../../../locales/namespaces";

import styles from "./reviews.module.css";

const Reviews = () => {
  const { t } = useTranslation(LANDING);

  return (
    <section className={styles.section}>
      <Container>
        <header className={styles.header}>
          <h2 className={styles.title}>
            {t("Our greatest certification is our customers!")}
          </h2>
          <p className={styles.text}>
            {t("Check out our numerous Google Business reviews that highlight the reliability and effectiveness of IMS.")}
          </p>
          <div className="elfsight-app-d669d24a-87f9-4a4c-9a12-3706b73cee49" data-elfsight-app-lazy />
        </header>
        <div className="elfsight-app-1c0742d8-bd1a-43ec-bef9-c1453a4895fc" data-elfsight-app-lazy />
      </Container>
    </section>
  )
}

export default Reviews
