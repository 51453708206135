// project import
import Navigation from './navigation';
import SimpleBar from '../../../../components/third-party/simpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
	return (
		<SimpleBar
			sx={{
				'& .simplebar-content': {
					display: 'flex',
					flexDirection: 'column'
				}
			}}
		>
			<Navigation/>
		</SimpleBar>
	);
};

export default DrawerContent;
