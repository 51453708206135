// @flow
import React from 'react';
import Index from "../../thinkPlus";
import HeaderWizard from "../../thinkPlus/components/wizard/HeaderWizard";

type Props = {

};

const AuthorizedWizard = (props: Props) => {
	return (
		<>
			<HeaderWizard/>
			<Index/>
		</>
	);
};

export default AuthorizedWizard