import * as Namespaces from './namespaces'
import React from 'react'

const locales = {
    [Namespaces.PARAMETERS]: {
        el: {
            'IMS Financial Consulting | Countries': 'IMS FC | Χώρες',
            'IMS Financial Consulting | Nationalities': 'IMS FC | Εθνικότητες',
            'IMS Financial Consulting | Roles': 'IMS FC | Ρόλοι',
            'IMS Financial Consulting | Rights': 'IMS FC | Δικαιώματα',
            'IMS Financial Consulting | Roles & Rights': 'IMS FC | Ρόλοι & Δικαιώματα',
            'IMS Financial Consulting | Loan Status': 'IMS FC | Κατάσταση Δανείου',
            'IMS Financial Consulting | Origin': 'IMS FC | Προέλευση',
            'IMS Financial Consulting | Loan Purpose': 'IMS FC | Σκοπός Δανείου',
            'IMS Financial Consulting | Calculation variables': 'IMS FC | Μεταβλητές Υπολογισμών',
            'IMS Financial Consulting | Questionnaires': 'IMS FC | Ερωτηματολόγια',
            'IMS Financial Consulting | Documents': 'IMS FC | Έγγραφα',
            'IMS Financial Consulting | Documents Associations': 'IMS FC | Συσχέτιση Εγγράφων',
            'IMS Financial Consulting | Documents Types': 'IMS FC | Τύποι Εγγράφων',
            'IMS Financial Consulting | Customer Status': 'IMS FC | Κατάσταση Πελάτη',
            'Add': 'Προσθήκη',
            'Actions': 'Ενέργειες',
            'Code': 'Κωδικός',
            'Erp Code': 'Κωδικός ERP',
            'Description': 'Περιγραφή',
            'Sort Description': 'Σύντομη περιγραφή',
            'Description EL': 'Περιγραφή EL',
            'Description EN': 'Περιγραφή EN',
            'Sort Description EL': 'Σύντομη Περιγραφή EL',
            'Sort Description EN': 'Σύντομη Περιγραφή EN',
            'Partner Description EL': 'Περιγραφή Συνεργάτη EL',
            'Partner Description EN': 'Περιγραφή Συνεργάτη EN',
            'Customer Status': 'Κατάσταση Πελάτη',
            'Countries': 'Χώρες',
            'Nationalities': 'Εθνικότητες',
            'Documents': 'Έγγραφα',
            'Groups': 'Ομάδες',
            'Resident of Greece': 'Κάτοικος Ελλάδας',
            'Loan Status': 'Κατάσταση Δανείου',
            'Loan Purpose': 'Σκοπός δανείου',
            'Documents Associations': 'Συσχέτιση Εγγράφων',
            'Documents Types': 'Τύποι Εγγράφων',
            'Default': 'Προκαθορισμένο',
            'Roles': 'Ρόλοι',
            'Role': 'Ρόλος',
            'Right': 'Δικαίωμα',
            'Attribute': 'Χαρακτηριστικό',
            'View Access': 'Πρόσβαση σε προβολή',
            'Edit Access': 'Πρόσβαση σε επεξεργασία',
            'Rights and roles': 'Δικαιώματα και ρόλοι',
            'Rights': 'Δικαιώματα',
            'Origin': 'Προέλευση',
            'Required': 'Υποχρεωτικό',
            'Document Code': 'Κωδικός εγγράφου',
            'Original File Name': 'Αρχικό όνομα αρχείου',
            'File Name': 'Όνομα αρχείου',
            'File Type': 'Τύπος αρχείου',
            'Active': 'Ενεργό',
            'Bank Spread': 'Τραπεζικό spread',
            'Submit': 'Καταχώριση',
            'Uploaded by': "Ανέβηκε από",
            "Stable Interest": "Σταθερό Επιτόκιο",
            "Contribution": "Εισφορά",
            'Calculation variables': 'Μεταβλητές υπολογισμών',
            'Property Commercial Value (€)': 'Εμπορική αξία ακινήτου (€)',
            'Loan amount (€)': 'Ποσό δανείου (€)',
            'Characters must be more than 3': 'Οι χαρακτήρες πρέπει να είναι περισσότεροι από 3',
            'Characters must be less than 50': 'Οι χαρακτήρες πρέπει να είναι λιγότεροι από 50',
            'The field must be number': 'Το πεδίο πρέπει να είναι αριθμός',
            'Number of days for installment notification': 'Αριθμός ημερών για ειδοποίηση δόσης',
            "Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία.",
            'Questionnaires': 'Ερωτηματολόγια',
            'Flag': 'Σημαία',
            'Phone Code': 'Κωδικός Τηλεφώνου',
            'Recommendation': 'Σύσταση'
        },
        en: {}
    },
}

export default locales