//@flow
import React, { useEffect, useMemo } from 'react';
import {useTranslation} from "react-i18next";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {DataGridPremium, GridEditInputCell} from '@mui/x-data-grid-premium';
import {PARAMETERS} from "../../locales/namespaces";
import useLoanStatus from "../../hooks/useLoanStatus";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import {textTranslate} from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import EditGridToolBar from "../../../../components/editableMUIDataGrid/partials/editGridToolBar";
import {CustomPagination} from "../../../../components/dataGridCustom/customPagination";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";

const LoanStatus = () => {
	const {t} = useTranslation(PARAMETERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Loan Status')
	}, [t])
	
	const {language} = useUserData()
	const {
		loading,
		loanStatus,
		update
	} = useLoanStatus()
	
	const newRowModel = {
		DescriptionEl: '',
		DescriptionEn: '',
		PartnerDescriptionEl: '',
		PartnerDescriptionEn: ''
	}
	
	const columns = useMemo(() => [
		/* {
			 field: 'id',
			 headerName: t('Description'),
			 minWidth: 160,
			 flex: 1,
			 renderCell: ({row}) => (textTranslate(language)({en: row.DescriptionEn, el: row.DescriptionEl}))
		 },*/
		{
			field: 'ErpCode',
			headerName: t('Description'),
			minWidth: 160,
			flex: 1,
			//editable: true
		},
		{
			field: 'DescriptionEl',
			headerName: t('Description EL'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'DescriptionEn',
			headerName: t('Description EN'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'PartnerDescriptionEl',
			headerName: t('Partner Description EL'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'PartnerDescriptionEn',
			headerName: t('Partner Description EN'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
	], [loanStatus, language])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Loan Status')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper autoHeight>
				<EditableMUIDataGrid
					sx={{maxHeight: 700}}
					loading={loading}
					data={loanStatus}
					columns={columns}
					newRowModel={newRowModel}
					update={update}
					hideDeleteButton={true}
					dataGridProOtherProps={{
						disableColumnMenu: true,
						slots: {
							toolbar: false,
							pagination: CustomPagination,
						}
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
		/>
	)
}

export default withPermissionHOC(LoanStatus, ['access_parameters']);

