import React from "react";
import { useLocation } from 'react-router';

import { ROUTE_PAGE_WIZARD } from "../../../routers/routes";
import HeaderLanding from "../landing/HeaderLanding";
import HeaderWizard from "../wizard/HeaderWizard";

const Header = () => {
  const { pathname } = useLocation();
  if (pathname === ROUTE_PAGE_WIZARD.path) {
    return <HeaderWizard />;
  }

  return <HeaderLanding />;
}

export default Header;
