import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.CUSTOMERS]: {
		el: {
			'IMS Financial Consulting | Customer Profile': 'IMS FC | Προφίλ Πελάτη',
			'IMS Financial Consulting | Customers': 'IMS FC | Πελάτες',
			'Customers': 'Πελάτες',
			'Leads': 'Πελάτες',
			'Home Page': 'Αρχική Σελίδα',
			'Customer Code': 'Κωδικός Πελάτη',
			'Login Name': 'Όνομα',
			'User Name': 'Όνομα Χρήστη',
			'Email (User Name)': 'Email (Όνομα xρήστη)',
			'Origin': 'Προέλευση',
			'Add': 'Προσθήκη',
			'Add Customer': 'Προσθήκη πελάτη',
			'Active': 'Ενεργός',
			'Full name': 'Ονοματεπώνυμο',
			'Password': 'Κωδικός',
			'Taxpayer identification number': 'Α.Φ.Μ.',
			'Country': 'Χώρα',
			'Mobile Number': 'Κινητό Τηλέφωνο',
			'Fixed telephone number': 'Σταθερό Τηλέφωνο',
			'Create Customer': 'Προσθήκη Πελάτη',
			'Submit': 'Καταχώριση',
			'Save': 'Αποθήκευση',
			'Cancel': 'Ακύρωση',
			'Confirm': 'Επιβεβαίωση',
			'Close': 'Κλείσιμο',
			'Return': 'Επιστροφή',
			'Customer Details': 'Λεπτομέρειες Πελάτη',
			'Currency': 'Νόμισμα',
			'Language': 'Γλώσσα',
			'Nationality': 'Εθνικότητα',
			'ERP Code': 'Κωδικός ERP',
			'Admin': 'Διαχειριστής',
			'IMS USER': 'χρήστης IMS',
			'Partner': 'Συνεργάτης',
			'Customer': 'Πελάτης',
			'Confirm Password': 'Επαλήθευση Κωδικού',
			'Edit': 'Επεξεργασία',
			'Profile': 'Προφίλ',
			'My Profile': 'Το προφίλ μου',
			'Edit Profile': 'Επεξεργασία Προφίλ',
			'Date Format': 'Μορφή Ημερομηνίας',
			'Decimal Character': 'Ψηφίο Δεκαδικών',
			'Customer Status': 'Κατάσταση Πελάτη',
			'Loan Status': 'Κατάσταση Δανείου',
			'Loan purpose': 'Σκοπός Δανείου',
			'History of calculations': 'Ιστορικό Υπολογισμών',
			'Information': 'Πληροφορίες',
			'Loan Request': 'Αίτημα για δάνειο',
			'Add loan scenario': 'Προσθήκη Υπολογισμού',
			'Logins History': 'Ιστορικό Συνδέσεων',
			'Customer Loans': 'Δάνεια Πελάτη',
			'Actions': 'Ενέργειες',
			'Import from excel': 'Εισαγωγή από Excel',
			'Customer export': 'Εξαγωγή πελατών',
			'Address': 'Διεύθυνση',
			'optional': 'Προαιρετικά',
			'(Optional)': '(Προαιρετικό)',
			'Loan Amount (€)': 'Ποσό Δανείου (€)',
			'Created': 'Δημιουργήθηκε',
			'Calling Code': 'Κωδ. τηλ/νου',
			'Phone Code': 'Κωδ. τηλ/νου',
			'Interest Rate (%)': 'Ονομαστικό επιτόκιο (%)',
			'Repayment In Years': 'Αποπληρωμή σε έτη',
			'Final Interest (%)': 'Τελικό Επιτόκιο (%)',
			'Monthly Allowance': 'Μηνιαία Δόση',
			'Total Repayment Amount (€)': 'Συνολικό Ποσό Αποπληρωμής (€)',
			'Date Login': 'Ημερ/νία Σύνδεσης',
			'Hour Login': 'Ώρα Σύνδεσης',
			'Failed Login': 'Αποτυχημένη Σύνδεση',
			'Failed Login Message': 'Μήνυμα Αποτυχημένης Σύνδεσης',
			'Customer Updated Successfully!': 'Ο πελάτης ενημερώθηκε με επιτυχία!',
			'Customer Stored Successfully!': 'Ο πελάτης αποθηκεύτηκε με επιτυχία!',
			'Failed to get customer details.': 'Αδυναμία φόρτωσης στοιχείων πελάτη.',
			"A user already exists with that email!": "Υπάρχει ήδη κάποιος χρήστης με αυτό το email!",
			"Are you sure you want to cancel the changes?": "Είστε σίγουροι ότι θέλετε να ακυρώσετε τις αλλαγές;",
			"If you press CONFIRM your changes will not be saved.": "Εάν πατήσετε ΕΠΙΒΕΒΑΙΩΣΗ οι αλλαγές σας δεν θα αποθηκευτούν.",
			'Required': 'Απαιτείται',
			'Enter a valid email': 'Εισαγάγετε έγκυρο email',
			'Enter a valid phone': 'Εισαγάγετε έγκυρο τηλέφωνο',
			'Enter a valid calling code': 'Εισαγάγετε έγκυρο κωδικό τηλεφώνου',
			'Enter a valid Taxpayer identification number': 'Εισαγάγετε έγκυρο ΑΦΜ',
			'Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character': 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας',
			'Passwords must match': 'Οι κωδικοί πρέπει να ταιριάζουν',
			"Submitting a home loan request requires completing your profile information.": "Για την υποβολή αιτήματος στεγαστικού δανείου απαιτείται η ολοκλήρωση των στοιχείων του προφίλ σας.",
			'Edit your information!': 'Επεξεργασία πληροφοριών!',
			'Registration was successful!': 'Η καταχώριση έγινε με επιτυχία!',
			'To complete your registration, you must confirm your email address by clicking on the link sent to your email.': 'Για να ολοκληρωθεί η εγγραφή σας πρέπει να επιβεβαιώσετε την ηλεκτρονική σας διεύθυνση πατώντας στον σύνδεσμο που έχει σταλθεί στο email σας.',
			'Your registration has been completed successfully! To activate your Account, please confirm your email address by clicking on the link sent to your email.': 'Η καταχώρισή σας ολοκληρώθηκε με επιτυχία! Για να ενεργοποιηθεί ο Λογαριασμός σας, παρακαλούμε επιβεβαιώστε την ηλεκτρονική σας διεύθυνση κάνοντας κλικ στον σύνδεσμο που έχει σταλεί στο email σας.',
			'Failed to get profile.': 'Αδυναμία φόρτωσης προφίλ.',
			'There are no registered customers.': 'Δεν υπάρχουν εγγεγραμμένοι πελάτες.',
			'Send an informative email to the customer': 'Αποστολή ενημερωτικού email στον πελάτη',
			'Enter name': 'Εισαγάγετε ονοματεπώνυμο',
			'Enter username': 'Εισαγάγετε όνομα χρήστη',
			'Enter password': 'Εισαγάγετε κωδικό',
			'Enter confirm password': 'Εισαγάγετε κωδικό επαλήθευσης',
			'Enter email': 'Εισαγάγετε email',
			'e.g.0030': 'π.χ.0030',
			'Enter mobile number': 'Εισαγάγετε κινητό τηλέφωνο',
			'Enter fixed telephone number': 'Εισαγάγετε σταθερό τηλέφωνο',
			'Enter taxpayer identification number': 'Εισαγάγετε ΑΦΜ',
			'Choose country': 'Επιλέξτε χώρα',
			'Choose language': 'Επιλέξτε γλώσσα',
			'Enter a valid code': 'Εισαγάγετε έγκυρο κωδικό',
			'Delete account': 'Διαγραφή λογαριασμού',
			'info_delete': ' Εάν πατήσετε επιβεβαίωση θα διαγραφούν όλα τα αποθηκευμένα στοιχεία και ο λογαριασμός σας.',
			'delete_account': ' Είστε βέβαιοι ότι θέλετε να ΔΙΑΓΡΑΨΕΤΕ οριστικά τον λογαριασμό σας;',
			'info_delete_for_admin': ' Εάν πατήσετε επιβεβαίωση θα διαγραφούν όλα τα αποθηκευμένα στοιχεία και ο λογαριασμός για τον συγκεκριμένο πελάτη.',
			'delete_account_for_admin': ' Είστε βέβαιοι ότι θέλετε να ΔΙΑΓΡΑΨΕΤΕ οριστικά αυτόν τον λογαριασμό;',
			'The account cannot be deleted because there is a registered loan.': 'Ο λογαριασμός δεν μπορεί να διαγραφεί γιατί υπάρχει καταχωριμένο δάνειο.',
			'Your account deleted successfully!': 'Ο λογαριασμός σας διαγράφηκε με επιτυχία!',
			'With request': 'Με αίτημα',
			'Comments': 'Σχόλια',
			'Value must be a positive number.': 'Η τιμή πρέπει να είναι θετικός αριθμός.',
			'Loan amount must be less than 100,000,000.': 'Το ποσό δανείου πρέπει να είναι μικρότερο από 100.000.000',
			'Enter loan amount': 'Εισαγάγετε ποσό δανείου',
			'The customer has consented to the processing of his data': 'Ο πελάτης έχει συναινέσει στην επεξεργασία των δεδομένων του',
			'Privacy Policy Consent by Customer': 'Συναίνεση πολιτικής απορρήτου από τον πελάτη',
			"Accepting the privacy statement is mandatory to proceed.": "Η αποδοχή της δήλωσης απορρήτου είναι υποχρεωτική για να προχωρήσετε.",
			"Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία.",
			"The customer's details are already registered in our system": "Τα στοιχεία του πελάτη είναι ήδη καταχωριμένα στο σύστημά μας",
			'I agree with the': 'Αποδέχομαι την',
			'Terms and Policies': 'δήλωση απορρήτου',
			'Profile saving completed successfully!': 'Η αποθήκευση του προφίλ ολοκληρώθηκε επιτυχώς!',
			'Unable to register customer': 'Αδυναμία καταχώρισης πελάτη'
		},
		en: {}
	},
}

export default locales