// @flow
import React, { useEffect, useMemo } from 'react';
import {useMuiGridServerSide} from "../../../../hooks/useMuiGridServerSide";
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import useRightsRoles from "../../hooks/useRightsRoles";
import useRights from "../../hooks/useRights";
import useRoles from "../../../users/hooks/useRoles";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";

type Props = {
	circularLoader?: boolean
}

const RightsRoles = (props: Props) => {
	const {t} = useTranslation(PARAMETERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Roles & Rights')
	}, [t])
	
	const url = `right/role/list`
	
	const {circularLoader} = props
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		initialSortModel: [{name: 'id', direction: 'descending'}]
	})
	
	const {
		loading,
		store,
		remove,
		update
	} = useRightsRoles({
		data,
		refreshGrid
	})
	
	const {rights} = useRights()
	const {roles} = useRoles()
	
	const newRowModel = {
		ViewAccess: true,
		EditAccess: true,
		Attribute: '',
		Description: '',
		// PortalRole: '',
		//PortalRoleId: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'ViewAccess',
			headerName: t('View Access'),
			width: 110, maxWidth: 150, flex: 1,
			type: 'boolean',
			editable: true,
			valueGetter: ({value}) => value === '1'
		},
		{
			field: 'EditAccess',
			headerName: t('Edit Access'),
			width: 120, flex: 1,
			type: 'boolean',
			editable: true,
			valueGetter: ({value}) => value === '1'
		},
		{
			field: 'PortalRoleId',
			headerName: t('Role'),
			minWidth: 160,
			flex: 1,
			editable: true,
			type: 'singleSelect',
			valueOptions: roles.map(role => ({
				value: role.id,
				label: role.Description
			})),
		}, {
			field: 'PortalRightId',
			headerName: t('Right'),
			minWidth: 160,
			flex: 1,
			editable: true,
			type: 'singleSelect',
			valueOptions: rights.map(right => ({
				value: right.id,
				label: right.Description
			})),
		},
	], [data, roles, refreshGrid])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Rights and roles')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper>
				<EditableMUIDataGrid
					data={data}
					columns={columns}
					loading={isLoading}
					newRowModel={newRowModel}
					store={store}
					remove={remove}
					update={update}
					dataGridProOtherProps={{
						pagination: true,
						paginationModel: {page, pageSize},
						paginationMode: 'server',
						onPaginationModelChange: onPaginationModelChange,
						rowCount: Number(total),
						rowsPerPageOptions: [5, 10, 15, 20, 50, 100],
						sortingMode: "server",
						onSortModelChange: (sortModel) => onSortModelChange(sortModel),
						filterMode: 'server',
						onFilterModelChange: onFilterModelChange,
						disableAggregation: true,
						disableRowGrouping: true
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
			useCircularLoader={circularLoader}
		/>
	)
};

export default withPermissionHOC(RightsRoles, ['access_parameters'])