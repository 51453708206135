import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_HOME } from "../../../routers/routes";

import Logo from "../../../theme/mantis/components/logo";
import Localization from "../../../theme/mantis/layout/mainLayout/header/headerContent/localization";

const HeaderWizard = () => {
  return (
    <Container component="header" maxWidth="md">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "32px",
          mt: "32px",
          bgcolor: "common.white",
          color: "text.primary",
          padding: { xs: "16px", md: "20px 48px" },
          borderRadius: "100px",
        }}
      >
        <Logo to={getRouteUrl(ROUTE_PAGE_HOME)}/>
        <Localization compact />
      </Box>
    </Container>
  );
}

export default HeaderWizard;
