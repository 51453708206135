// @flow
import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CUSTOMERS} from "../../locales/namespaces";
import {Grid, Typography, Toolbar, Divider, useMediaQuery} from "@mui/material";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_CUSTOMERS_INDEX} from "../../routes/routes";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes"
import axios from 'axios'
import useUserData from "../../../../hooks/user/useUserData";
import {API} from "../../../../helpers/constants";
import CalculationsHistory from "../../components/calculationsHistory/calculationsHistory";
import LoginHistory from "../../components/loginHistory/loginHistory";
import PermissionHOC, {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import Loans from "../../components/loans/loans";
import Profile from "../../components/profile/profile";
import AlertMessageForSimpleUser from "../../components/alertMessageForSimpleUser/alertMessageForSimpleUser";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../../helpers/errorHandling";
import useUserAction from "../../../../hooks/user/useUserAction";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";

const Details = () => {
	const {token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	const {id} = useParams()
	const {t} = useTranslation(CUSTOMERS)
	const [loans, setLoans] = useState([])
	const [customer, setCustomer] = useState({})
	const [loading, setLoading] = useState(false)
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Customer Profile')
	}, [t])
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'post',
			data: {CustId: id},
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/customer/details/data`,
		}).then(response => {
			const data = response.data
			const customer = data.customer
			const loans = data.loans
			setCustomer({
				...customer,
				isActive: customer.isActive === '1',
			})
			setLoans(loans)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get profile.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [id])
	
	return !loading ?
		(
			<Fragment>
				{!matchDownSM &&
					<PermissionHOC requiredPermissions={['access_customer_index']}>
						<Breadcrumb>
							<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
							<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_CUSTOMERS_INDEX)}>
								{t('Customers')}
							</Breadcrumb.Link>
							<Breadcrumb.Item>
								{t('Profile')}
							</Breadcrumb.Item>
						</Breadcrumb>
					</PermissionHOC>
				}
				{!matchDownSM &&
					<PermissionHOC requiredPermissions={['access_customer_details_breadcrum']}>
						<Breadcrumb>
							<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
							<Breadcrumb.Item>
								{t('Profile')}
							</Breadcrumb.Item>
						</Breadcrumb>
					</PermissionHOC>
				}
				<Divider sx={{mb: 2}} light/>
				<AlertMessageForSimpleUser/>
				<PermissionHOC requiredPermissions={['access_customer_profile']}>
					<Profile
						id={id}
						customer={customer}
						loans={loans}
					/>
				</PermissionHOC>
				<PermissionHOC requiredPermissions={['view_cust_log_history']}>
					<Grid container spacing={3} sx={{mb: 2}}>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Profile
								id={id}
								customer={customer}
								loans={loans}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<MainCard title={
								<Toolbar disableGutters variant={"dense"}>
									<Typography variant="h5" gutterBottom>
										{t('Loan Request')}
									</Typography>
								</Toolbar>
							}>
								<Loans
									loading={loading}
									loans={loans}
								/>
							</MainCard>
						</Grid>
					</Grid>
				</PermissionHOC>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<PermissionHOC requiredPermissions={['view_cust_log_history']}>
							<MainCard sx={{mb: 2}} title={
								<Toolbar disableGutters variant={"dense"}>
									<Typography variant="h5" gutterBottom>{t('History of calculations')}</Typography>
								</Toolbar>
							}>
								<CalculationsHistory/>
							</MainCard>
						</PermissionHOC>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<PermissionHOC requiredPermissions={['view_cust_log_history']}>
							<MainCard sx={{mb: 2}} title={
								<Toolbar disableGutters variant={"dense"}>
									<Typography variant="h5" gutterBottom>{t('Logins History')}</Typography>
								</Toolbar>
							}>
								<LoginHistory/>
							</MainCard>
						</PermissionHOC>
					</Grid>
				</Grid>
			</Fragment>
		) : (
			<ContentLoader
				loading={loading}
			/>
		)
}

export default withPermissionHOC(Details, ['access_customer_details'])