import React from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import useUserData from "../../../hooks/user/useUserData";
import { useWizard } from "../wizard/WizardContext";
import { textTranslate } from "../../../helpers/helpers";
import ArrowDown2 from "../../../assets/images/icons/arrowDown2";

const SelectField = ({ itemId, options }) => {
  const { language } = useUserData();
  const { responses, updateResponse } = useWizard();

  const handleChange = (e) => {
    if (e.target.value === "") {
      updateResponse(itemId);
      return;
    }

    const response = options.find(
      (option) => option.AnswerId === e.target.value
    );

    updateResponse(itemId, {
      AnswerId: response.AnswerId,
      AnswerDescription: textTranslate(language)({
        el: response.AnswerDescriptionEl,
        en: response.AnswerDescriptionEn,
      })
    });
  }

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        IconComponent={ArrowDown2}
        value={responses[itemId]?.AnswerId || ""}
        onChange={handleChange}
        sx={{
          py: 1,
          px: 0.5,
          border: "1px solid #dddee1",
          borderRadius: "8px",
          background: "none",
          ".MuiSelect-icon": {
            right: "14px",
          }
        }}
      >
        {options.map((option) => {
          const { AnswerId, AnswerDescriptionEl, AnswerDescriptionEn } = option;
          const answerDescription = textTranslate(language)({
            el: AnswerDescriptionEl,
            en: AnswerDescriptionEn,
          });

          return (
            <MenuItem key={AnswerId} value={AnswerId}>
              <Typography>
                {answerDescription}
              </Typography>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
}

export default SelectField;
