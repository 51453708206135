import * as Namespaces from './namespaces'
import React from 'react'

const locales = {
	[Namespaces.USERS]: {
		el: {
			'IMS Financial Consulting | User Profile': 'IMS FC | Προφίλ Χρήστη',
			'IMS Financial Consulting | Users': 'IMS FC | Χρήστες',
			'Login Name': 'Όνομα',
			'User Name': 'Όνομα Χρήστη',
			'Origin': 'Προέλευση',
			'Culture': 'Ιθαγένεια',
			'Add': 'Προσθήκη',
			'Active': 'Ενεργός',
			'Users Table': 'Πίνακας Χρηστών',
			'Users': 'Χρήστες',
			'Name': 'Επωνυμία',
			'Password': 'Κωδικός',
			'Taxpayer identification number': 'Α.Φ.Μ',
			'Country': 'Χώρα',
			'Address': 'Διεύθυνση',
			'County': 'Νόμος',
			'City': 'Πόλη',
			'Postal Code': 'Τ.Κ',
			'Mobile': 'Κινητό',
			'Phone': 'Τηλέφωνο',
			'Create User': 'Δημιουργία Χρήστη',
			'Create': 'Δημιουργία',
			'Submit': 'Καταχώριση',
			'Save': 'Αποθήκευση',
			'Close': 'Ακύρωση',
			'User Details': 'Λεπτομέρειες Χρήστη',
			'Currency': 'Νόμισμα',
			'Language': 'Γλώσσα',
			'Admin': 'Διαχειριστής',
			'IMS USER': 'Χρήστης IMS',
			'Partner': 'Συνεργάτης',
			'Customer': 'Πελάτης',
			'Description': 'Περιγραφή',
			'Confirm Password': 'Επαλήθευση Κωδικού',
			'Edit': 'Επεξεργασία',
			'Edit Profile': 'Επεξεργασία Προφίλ',
			'User Profile': 'Προφίλ Χρήστη',
			'Delete': 'ΔΙΑΓΡΑΦΗ',
			'Edit User': 'Επεξεργασία Χρήστη',
			'Housing Loan': 'Στεγαστικό Δάνειο',
			'Full Name': 'Ονοματεπώνυμο',
			'Loan Amount': 'Ποσό Δανείου',
			'Property Commercial Value': 'Εμπορική Αξία Ακινήτου',
			'House Location': 'Περιοχή που βρίσκεται το ακίνητο',
			'Comments': 'Σχόλια',
			'I agree with the Terms and Policies': 'Αποδέχομαι την δήλωση απορρήτου',
			'Send': 'ΑΠΟΣΤΟΛΗ',
			'Reset': 'Επαναφορά',
			'Reset Password': 'Επαναφορά κωδικού',
			'Fill in the new password.': 'Συμπληρώστε τον νέο κωδικό',
			'New Password': 'Νέος Κωδικός',
			'Send new password via e-mail': 'Αποστολή κωδικού με e-mail',
			'Roles': 'Ρόλοι',
			'Teams': 'Ομάδες',
			'History Login': 'Ιστορικό Συνδέσεων',
			'Loan Calculation': 'Υπολογισμός Δανείου',
			'User created successfully!': 'Ο χρήστης δημιουργήθηκε με επιτυχία!',
			'User updated successfully!': 'Ο χρήστης ενημερώθηκε με επιτυχία!',
			'Home Page': 'Αρχική Σελίδα',
			'Nationality': 'Εθνικότητα',
			'Last Login': 'Τελευταία Σύνδεση',
			'Failed to get user details.': 'Αδυναμία φόρτωσης λεπτομερειών χρήστη.',
			'Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character': 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας',
			'Required': 'Απαιτείται',
			'Enter a valid email': 'Εισαγάγετε έγκυρο email',
			'Passwords must match': 'Οι κωδικοί πρέπει να ταιριάζουν',
			'Information': 'Πληροφορίες',
			'Profile': 'Προφίλ',
			'Cancel': 'Ακύρωση',
			'Failed to get profile.': 'Αδυναμία φόρτωσης προφίλ.',
			'Password changed successfully!': 'Επιτυχής αλλαγή κωδικού!',
			'Failed to change password.': 'Αδυναμία αλλαγής κωδικού.',
			'Required!': 'Υποχρεωτικό!',
			'Enter more than 6 characters.': 'Εισαγάγετε 6 ή περισσότερους χαρακτήρες.',
			'Enter name': 'Εισαγάγετε επωνυμία',
			'Enter username': 'Εισαγάγετε όνομα χρήστη',
			'Enter password': 'Εισαγάγετε κωδικό',
			'Enter confirm password': 'Εισαγάγετε κωδικό επαλήθευσης',
			'Enter email': 'Εισαγάγετε email',
			'e.g.0030': 'π.χ.0030',
			'Enter mobile phone': 'Εισαγάγετε κινητό τηλέφωνο',
			'Enter fixed telephone number': 'Εισαγάγετε σταθερό τηλέφωνο',
			'Enter taxpayer identification number': 'Εισαγάγετε ΑΦΜ',
			'Choose country': 'Επιλέξτε χώρα',
			'Choose language': 'Επιλέξτε γλώσσα',
			'Choose partner': 'Επιλέξτε συνεργάτη',
			"Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία.",
			'Delete account': 'Διαγραφή λογαριασμού',
			'Confirm': 'Επιβεβαίωση',
			'info_delete': ' Εάν πατήσετε επιβεβαίωση θα διαγραφούν όλα τα αποθηκευμένα στοιχεία και ο λογαριασμός σας.',
			'delete_account': ' Είστε βέβαιοι ότι θέλετε να ΔΙΑΓΡΑΨΕΤΕ οριστικά τον λογαριασμό σας;',
			'info_delete_for_admin': ' Εάν πατήσετε επιβεβαίωση θα διαγραφούν όλα τα αποθηκευμένα στοιχεία και ο λογαριασμός για τον συγκεκριμένο χρήστη.',
			'delete_account_for_admin': ' Είστε βέβαιοι ότι θέλετε να ΔΙΑΓΡΑΨΕΤΕ οριστικά αυτόν τον λογαριασμό;',
			'The account cannot be deleted because there is a registered loan.': 'Ο λογαριασμός δεν μπορεί να διαγραφεί γιατί υπάρχει καταχωριμένο δάνειο.',
			'Your account deleted successfully!': 'Ο λογαριασμός σας διαγράφηκε με επιτυχία!',
			'Send Emails': 'Αποστολή Email',
			'Access Api': 'Πρόσβαση στο API',
			'Enter Public Ip': 'Εισαγάγετε Public Ip'
		},
		en: {}
	},
}

export default locales