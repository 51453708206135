import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.CALCULATIONS]: {
		el: {
			'IMS Financial Consulting | Calculations': 'IMS FC | Υπολογισμοί',
			'IMS Financial Consulting | Mortgage Calculator': 'IMS FC | Υπολογιστής Δόσης Δανείου',
			'Use our mortgage calculator to estimate your monthly payments and find the best loan options. Trusted mortgage solutions by IMS Financial Consulting.': 'Χρησιμοποιήστε τον υπολογιστή δόσης στεγαστικού δανείου  για να εκτιμήσετε τις μηνιαίες σας δόσεις και να βρείτε τις καλύτερες επιλογές δανείου. Αξιόπιστες λύσεις από την IMS FC.',
			'Estimate your mortgage payments with our calculator. Get accurate results and explore the best mortgage options available in Greece.': 'Εκτιμήστε τις μηνιαίες δόσεις του στεγαστικού σας δανείου με τον υπολογιστή μας. Λάβετε ακριβή αποτελέσματα και εξερευνήστε τις καλύτερες επιλογές στεγαστικών δανείων στην Ελλάδα.',
			'Quickly calculate your mortgage payments and discover the best loan options with IMS FC mortgage calculator.': 'Υπολογίστε γρήγορα τις δόσεις του στεγαστικού σας δανείου με δυνατότητα προσθήκης αποπληρωμών και ανακαλύψτε τα καλύτερα επιτόκια με τον υπολογιστή στεγαστικού δανείου της IMS FC.',
			'Property Commercial Value (€)': 'Εμπορική αξία ακινήτου (€)',
			'Loan amount (€)': 'Ποσό δανείου (€)',
			'Interest rate type': 'Τύπος επιτοκίου',
			'Fixed interest rate (%)': 'Σταθερό επιτόκιο (%)',
			'Floating rate (%)': 'Κυμαινόμενο επιτόκιο (%)',
			'Duration (in years)': 'Διάρκεια (σε έτη)',
			'Tax Law.128 (%)': 'Εισφορά Ν.128 (%)',
			'Bank spread (%)': 'Τραπεζικό spread (%)',
			'Loan to Value': 'Ποσοστό χρηματοδότησης',
			'Total duration (in years)': 'Συνολική διάρκεια (σε έτη)',
			'Total interest rate': 'Συνολικό επιτόκιο',
			'Number of installments': 'Αριθμός δόσεων',
			'Date of first installment': 'Ημερομηνία πρώτης δόσης',
			'I want to receive notifications about my monthly installment': 'Θέλω να λαμβάνω ειδοποιήσεις για τη μηνιαία δόση μου',
			'Yearly': 'Ετησίως',
			'Monthly': 'Μηνιαίως',
			'Hide previous installments': 'Απόκρυψη προηγούμενων δόσεων',
			'Create calculation': 'Δημιουργία υπολογισμού',
			'Calculation Details': 'Λεπτομέρειες Υπολογισμού',
			'Calculation': 'Υπολογισμός',
			'Edit calculation': 'Επεξεργασία υπολογισμού',
			'Add': 'Προσθήκη',
			'Add loan scenario': 'Προσθήκη Υπολογισμού',
			'Submit': 'Καταχώριση',
			'Close': 'Ακύρωση',
			'Delete': 'Διαγραφή',
			'Reset': 'Καθαρισμός',
			'Cancel': 'Ακύρωση',
			'View': 'Προβολή',
			'Confirm': 'Επιβεβαίωση',
			'Calculations': 'Υπολογισμοί',
			'Installment Calculation': 'Υπολογισμός δόσης',
			'Home Page': 'Αρχική Σελίδα',
			'Actions': 'Επιλογές',
			'Details': 'Λεπτομέρειες',
			'Edit': 'Επεξεργασία',
			'Save': 'Αποθήκευση',
			'Created': 'Δημιουργήθηκε',
			"Calculations Information": "Πληροφορίες Υπολογισμών",
			'Calculation Stored Successfully!': 'Ο υπολογισμός αποθηκεύτηκε με επιτυχία!',
			'Calculation deleted successfully!': 'Ο υπολογισμός διαγράφτηκε με επιτυχία!',
			'Calculation Updated Successfully!': 'Ο υπολογισμός ενημερώθηκε με επιτυχία!',
			'Failed to get calculation.': 'Αδυναμία φόρτωσης υπολογισμού.',
			'N/A': 'Α/Α',
			'Fixed interest monthly installment': 'Μηνιαία δόση σταθερού επιτοκίου',
			'Monthly floating rate installment': 'Μηνιαία δόση κυμαινόμενου επιτοκίου',
			'I am interested in applying for a loan': 'Ενδιαφέρομαι να κάνω αίτηση για δάνειο',
			'Monthly Installment': 'Μηνιαία Δόση',
			'Interest': 'Τόκος',
			'Amortization': 'Χρεολύσιο',
			'Remaining Principal': 'Υπόλοιπο Κεφάλαιο',
			'Total Interest': 'Σύνολο Τόκων',
			'Total principal repayment': 'Σύνολο αποπληρωμής κεφαλαίου',
			'Fixed rate Installments': 'Δόσεις Σταθερού Δανείου',
			'Float rate Installments': 'Δόσεις Κυμαινόμενου Δανείου',
			'Value must be a positive number.': 'Η τιμή πρέπει να είναι θετικός αριθμός.',
			'Value must be an integer number.': 'Η τιμή πρέπει να είναι ακέραιος αριθμός.',
			'Value must be a valid date.': 'Η τιμή πρέπει να είναι έγκυρη μορφή ημερομηνίας.',
			'Failed to get details.': 'Αδυναμία φόρτωσης λεπτομερειών.',
			'No loan scenarios have been added yet.': 'Δεν έχω ακόμα υπολογισμούς δόσης στεγαστικού δανείου.',
			'Loan amount must be less than 100,000,000.': 'Το ποσό δανείου πρέπει να είναι μικρότερο από 100.000.000',
			'Real estate value must be less than 100,000,000.': 'Η εμπορική αξία ακινήτου πρέπει να είναι μικρότερη από 100.000.000',
			'The value is too big.': 'Η τιμή είναι πολύ μεγάλη.',
			'Required': 'Απαιτείται',
			'The total duration of the loan cannot exceed 45 years': 'Η συνολική διάρκεια του δανείου δεν μπορεί να υπερβαίνει τα 45 χρόνια',
			'Repayments': 'Αποπληρωμές',
			'Repayment': 'Αποπληρωμή',
			'Add repayment': 'Προσθήκη αποπληρωμής',
			'Edit repayment': 'Επεξεργασία αποπληρωμής',
			'Repayment amount (€)': 'Ποσό αποπληρωμής (€)',
			'Date of repayment': 'Ημερομηνία αποπληρωμής',
			'New interest rate (%)': 'Νέο επιτόκιο (%)',
			'Loan Calculation Tables': 'Πίνακες υπολογισμού',
			'Calculation Diagrams': 'Διαγράμματα υπολογισμού',
			'Payment date': 'Ημερομηνία Δόσης',
			'Repayment amount must be less than to the loan amount.': 'Το ποσό αποπληρωμής πρέπει να είναι μικρότερο από το ποσό του δανείου.',
			'Repayment date must not be before the first installment date.': 'Η ημερομηνία αποπληρωμής δεν πρέπει να είναι πριν από την ημερομηνία πρώτης δόσης.',
			'Repayment date must be within the total loan duration.': 'Η ημερομηνία αποπληρωμής πρέπει να είναι εντός της συνολικής διάρκειας του δανείου.',
			'Repayment Stored Successfully!': 'Η αποπληρωμή αποθηκεύτηκε με επιτυχία!',
			'Failed to get repayment.': 'Αδυναμία φόρτωσης αποπληρωμής.',
			'Repayments deleted successfully!': 'Οι αποπληρωμές διαγράφτηκαν με επιτυχία!',
			'Failed to delete repayments!': 'Αδυναμία διαγραφής αποπληρωμών!',
			'Failed to delete calculation!': 'Αδυναμία διαγραφής υπολογισμού!',
			"Are you sure you want to reset the calculation?": 'Είστε βέβαιοι ότι επιθυμείτε καθαρισμό υπολογισμού;',
			"If you press CONFIRM your repayments will be deleted.": 'Εάν πατήσετε ΕΠΙΒΕΒΑΙΩΣΗ, οι αποπληρωμές σας θα διαγραφούν.',
			'interest_type': 'Είναι το επιπλέον κόστος που προκύπτει από το ποσό δανεισμού. Για το στεγαστικό δάνειο μπορείτε να επιλέξετε:',
			'- Fixed interest rate,': '- Σταθερό επιτόκιο,',
			'which remains unchanged for an agreed period of time.': 'το οποίο παραμένει αμετάβλητο για συμφωνημένο χρονικό διάστημα.',
			'- Floating interest rate,': '- Κυμαινόμενο επιτόκιο,',
			'which is linked to euribor, plus a margin (spread), which is "locked in" when the contract is signed.': 'το οποίο συνδέεται με το euribor, πλέον ενός περιθωρίου (spread), το οποίο «κλειδώνει» κατά την υπογραφή της σύμβασης.',
			'- Fixed and Floating,': '- Σταθερό και Κυμαινόμενο,',
			'which is initially fixed for some years and floating for the remaining duration (e.g. first 5 years fixed and then floating).': 'που είναι αρχικά σταθερό για ορισμένα χρόνια και κυμαινόμενο για την υπόλοιπη διάρκεια (π.χ. 5 πρώτα χρόνια σταθερό και μετά κυμαινόμενο).',
			'To apply for a loan you must have an account.': 'Για να κάνετε αίτηση για δάνειο πρέπει να έχετε λογαριασμό.',
			'Scenario description': 'Περιγραφή υπολογισμού',
			'Loan criteria': 'Κριτήρια υπολογισμού',
			"Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία.",
			'Added up to the interest rate and attributed to the Greek State': 'Προστίθεται στο επιτόκιο και αποδίδεται στο Ελληνικό Δημόσιο',
			'Next loan installment is in': 'Η επόμενη δόση πληρωμής είναι σε',
			'days': 'μέρες',
			'day': 'μέρα',
			'Help': 'Βοήθεια',
			'Next': 'Επόμενο',
			'Enter a descriptive title for your calculation': 'Συμπληρώστε έναν χαρακτηριστικό τίτλο για τον υπολογισμό σας',
			'Yearly Installment': 'Ετήσια Δόση',
			'Fill in a characteristic title for your calculation (e.g. Buy a holiday home)': 'Συμπληρώστε έναν χαρακτηριστικό τίτλο για τον υπολογισμό σας (πχ Αγορά εξοχικού)',
			'The commercial value of a property refers to the value of a property as determined by market conditions in the specific time period.': 'Η εμπορική αξία ενός ακινήτου αφορά στην αξία ενός ακινήτου όπως διαμορφώνεται από τις συνθήκες της αγοράς την συγκεκριμένη χρονική περίοδο.',
			'The loan amount I wish to request from the bank': 'Το ποσό δανείου που επιθυμώ να αιτηθώ από την τράπεζα',
			'It is added to the interest rate and attributed to the Greek State. It is generally applied to all mortgages except for a few special exceptions.': 'Προστίθεται στο επιτόκιο και αποδίδεται στο Ελληνικό Δημόσιο. Εφαρμόζεται κατά κανόνα σε όλα τα στεγαστικά δάνεια πλην κάποιων ελάχιστων ειδικών εξαιρέσεων.',
			'A percentage which is added to the base rate (Euribor) to calculate the total variable rate of your home loan. Essentially, this is the bank s profit margin, which is set by the bank at the beginning of the loan and is unchanged for its entire duration.': 'Ένα ποσοστό το οποίο προστίθεται στο επιτόκιο βάσης (Euribor) για να υπολογιστεί το συνολικό κυμαινόμενο επιτόκιο του στεγαστικού δανείου σας. Ουσιαστικά πρόκειται για το περιθώριο κέρδους της τράπεζας το οποίο ορίζεται από την στην αρχή του δανείου και είναι αμετάβλητο για όλη τη διάρκειά του.',
			'Euribor is the market reference rate at which credit institutions in the European Union borrow in euros through the wholesale market.': 'Το Euribor είναι το επιτόκιο αναφοράς της αγοράς με το οποίο πιστωτικά ιδρύματα στην Ευρωπαϊκή Ένωση δανείζονται σε ευρώ μέσω της χονδρικής αγοράς.',
			'This is a percentage that results from Loan amount/ Commercial value of the property': 'Πρόκειται για ένα ποσοστό το οποίο προκύπτει από Ποσό δανείου/ Εμπορική αξία του ακινήτου',
			//"Installment Date": ""
		},
		en: {
			'interest_type': 'It is the additional cost resulting from the loan amount. For the mortgage you can choose:'
			
		}
	},
}

export default locales