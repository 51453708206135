import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

import { WIZARD } from "../../../locales/namespaces";
import { useWizard } from "../WizardContext";
import useUserData from "../../../../hooks/user/useUserData";
import QuestionTitle from "../../elements/QuestionTitle";
import AmountField from "../../elements/AmountField";
import InfoMessage from "../../elements/InfoMessage";
import { intlNumber } from "../../../utils";

const LoanAmount = () => {
  const { t } = useTranslation(WIZARD);
  const { responses } = useWizard();
  const { language } = useUserData();

  const propertValue = intlNumber(responses["3"]?.AnswerDescription, language);

  return (
    <>
      <QuestionTitle
        title="What loan amount would you like to apply for?"
        helperText={`(${t("Property value")} ${propertValue})`}
        main
      />
      <Stack spacing={3}>
        <AmountField itemId="10" />
        <InfoMessage
          title="Financing percentage or otherwise LTV (Loan to Value)"
          message="ltv_description"
        />
      </Stack>
    </>
  );
}

export default LoanAmount;
