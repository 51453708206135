export const mode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'development' : 'production'
export const configuration = window.GLOBAL_CONFIGURATION[mode]
export const API = mode === 'production' ? configuration.API_HOST[window.location.hostname] : configuration.API_HOST;
export const API_PORT = API?.substring(API?.lastIndexOf(':') + 1)
export const API_DOMAIN = API?.substring(0, API?.lastIndexOf(':'))
console.log(API, API_PORT, API_DOMAIN)
export const PortalToken = window.GLOBAL_CONFIGURATION.portalToken

export const Languages = {
	EL: 'el',
	EN: 'en',
}

export const Permissions = {
	BeAdmin: "ADMIN_CONTENT",
}

export const Roles = {
	Admin: 1
}

export const ATTACHMENTS_RELATIVE_PATH = '/attachments/'

export const BulkImportStatus = {
	AllSuccessful: 1,
	AllErrors: 2,
	SucceededWithErrors: 3,
	NothingToDo: 4,
}

export const DocsDownloadTypes = {
	CustomerExcel: 1,
	GDPR: 2
}