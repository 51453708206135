import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from "@mui/material/Box";
import {
	DialogContent, DialogTitle, Divider, FormControl, FormControlLabel,
	FormLabel, Grid, Switch, TextareaAutosize,
	useTheme, Button, Typography, Stack, FormHelperText
} from "@mui/material";
import phoneGif from "../../assets/images/phone.gif";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import {ErrorMessage, FormikProvider} from "formik";
import useSupportDialog from "./useSupportDialog";
import PrivacyPolicy from "../privacyPolicy/privacyPolicy";

const SupportDialogMobile = (props) => {
	const {onClose, selectedValue, open} = props
	//const {user_email} = useUserData()
	const {t} = useTranslation(PAGE_HOME)
	const theme = useTheme();
	const [openModal, setOpenModal] = React.useState(false)
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	
	const handleClose = () => {
		onClose(selectedValue)
		formik.resetForm()
	};
	
	const {formik} = useSupportDialog({handleClose})
	
	const textareaStyle = {
		display: 'flex',
		height: '144px',
		padding: '8px 16px',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '10px',
		alignSelf: 'stretch',
		borderRadius: '8px',
		border: ' 0px solid #CFD3D4',
		background: '#F4F5FA',
		overflow: 'auto',
		width: '100%',
		fontSize: '1rem'
	}
	
	const upXS = useMediaQuery(theme.breakpoints.between('540', '680'));
	const matchDownMD = useMediaQuery(theme.breakpoints.between('680', '1024'));
	
	return (
		<Dialog
			onClose={() => {
				handleClose()
			}}
			open={open}
			sx={{
				'& .MuiPaper-root': {
					border: '1px solid #0BC1B6',
					borderRadius: '12px'
				},
			}}
		>
			<Box
				sx={{
					minWidth: upXS ? 500 : matchDownMD ? 680 : 'none'
				}}
			>
				<DialogTitle>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item xs={8} sm={8} md={8} lg={6}>
							<Typography
								sx={{
									mt: 1,
									fontSize: '18px',
									fontWeight: 400,
									lineHeight: '20px',
									letterSpacing: '0em',
									textAlign: 'left'
								}}
							>
								{t('Contact us')}
							</Typography>
						</Grid>
						<Grid item>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row'
								}}>
								<Button
									onClick={() => {
										handleClose()
									}}
									sx={{
										fontWeight: 600,
										fontSize: '20px',
										lineHeight: '24.52px',
									}}
								>
									x
								</Button>
							</div>
						</Grid>
					</Grid>
					<Divider
						sx={{
							strokeWidth: '1px',
							stroke: '#D3D5DF',
							height: '0px',
							transform: 'rotate(-0.027deg)',
							alignSelf: 'stretch',
							mt: 2,
							mb: 2
						}}
					/>
				</DialogTitle>
				<DialogContent sx={{p: 1}}>
					<FormikProvider value={formik}>
						<Box sx={{p: 0.5}}>
							<FormControl
								variant="standard"
								fullWidth
								error={
									formik.touched["Body"] &&
									Boolean(formik.errors["Body"])
								}
								sx={{mb: 1}}
							>
								<FormLabel htmlFor="Body" required>{t('Message')}</FormLabel>
								<TextareaAutosize
									aria-label="maximum height"
									maxRows={4}
									value={formik.values.Body}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									name={'Body'}
									style={textareaStyle}
									size={'small'}
								/>
								<FormHelperText>
									<ErrorMessage name={"Body"}/>
								</FormHelperText>
							</FormControl>
							<FormControl
								variant="standard"
								fullWidth
								error={
									formik.touched["isInformed"] &&
									Boolean(formik.errors["isInformed"])
								}
								sx={{pb: 1, mt: 2}}
							>
								<FormControlLabel required control=
									{
										<Switch
											checked={formik.values.isInformed}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											size={'small'}
											name={'isInformed'}
										/>
									} label={
									<Typography
										sx={{
											fontSize: '12px',
											fontStyle: 'normal',
											fontWeight: 600,
											lineHeight: 'normal'
										}}
									>
										{t('I agree with the')}{' '}
										<Button
											onClick={handleOpenModal}
											sx={{
												fontSize: '12px',
												fontStyle: 'normal',
												fontWeight: 600,
												lineHeight: 'normal',
												textDecorationLine: 'underline',
												m: 0,
												p: 0
											}}
										>
											{t('Terms and Policies')}
										</Button>
									</Typography>
								} labelPlacement={"end"}
								/>
								<FormHelperText>
									<ErrorMessage name={"isInformed"}/>
								</FormHelperText>
							</FormControl>
							<Dialog onClose={handleCloseModal} open={openModal}>
								<PrivacyPolicy isModal={true} handleCloseModal={handleCloseModal}/>
							</Dialog>
							<Divider
								sx={{
									strokeWidth: '1px',
									stroke: '#D3D5DF',
									height: '0px',
									transform: 'rotate(-0.027deg)',
									alignSelf: 'stretch',
									mt: 1,
									mb: 1
								}}
							/>
							<Button
								size={"small"}
								variant={"contained"}
								color={'info'}
								onClick={(event) => {
									event.stopPropagation()
									formik.submitForm()
								}}
								sx={{
									gap: '12px',
									padding: '15px 45px 15px 45px',
									mb: 1
								}}
							>
								{t('Send')}
							</Button>
						</Box>
					</FormikProvider>
					<Typography
						sx={{
							fontSize: '18px',
							fontWeight: 400,
							lineHeight: '25px',
							letterSpacing: '0em',
							textAlign: 'left',
							mt: 2,
							mb: 1.5
						}}
					>
						{t('or call us')}
					</Typography>
					<Divider
						sx={{
							strokeWidth: '1px',
							stroke: '#D3D5DF',
							height: '0px',
							transform: 'rotate(-0.027deg)',
							alignSelf: 'stretch',
						}}
					/>
					<Stack direction={'row'}>
						{/*<Phone/>*/}
						<a
							href="tel:2109520047"
							style={{textDecoration: 'none', color: '#485776'}}
						>
							<img src={phoneGif} width={100} height={100} color={'#656161'} alt="Call us"/>
						</a>
						<Stack direction={'column'} sx={{mt: 2}}>
							<Typography
								sx={{
									fontSize: '14px',
									fontWeight: 600,
									lineHeight: '24px',
									letterSpacing: '0em',
									textAlign: 'left'
								}}
							>
								{t('Phone service')}
							</Typography>
							<Typography
								sx={{
									fontSize: '20px',
									fontWeight: 300,
									lineHeight: '46px',
									letterSpacing: '0em',
									textAlign: 'left',
									color: '#485776'
								}}
							>
								<a
									href="tel:2109520047"
									style={{textDecoration: 'none', color: '#485776'}}
								>
									210 9520047-48
								</a>
							</Typography>
							<Typography
								sx={{
									fontSize: '12px',
									fontWeight: 400,
									lineHeight: '24px',
									letterSpacing: '0em',
									textAlign: 'left'
									
								}}
							>
								{t('work_hours')}
							</Typography>
						</Stack>
					</Stack>
				</DialogContent>
			</Box>
		</Dialog>
	);
}

export default SupportDialogMobile