//@flow
import React, { useEffect, useMemo } from 'react';
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import useLoanType from "../../hooks/useLoanType";
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";
import EditableMUIDataGrid from "../../../../components/editableMUIDataGrid/editableMUIDataGrid";
import {CustomPagination} from "../../../../components/dataGridCustom/customPagination";
import EditableMUICellValidation from "../../../../components/editableMUICellValidation/editableMUICellValidation";
import {GridEditInputCell} from "@mui/x-data-grid-premium";

type Props = {
	circularLoader?: boolean
}

const LoanType = (props: Props) => {
	const {t} = useTranslation(PARAMETERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Loan Purpose')
	}, [t])
	
	const {
		loading,
		loanType,
		update
	} = useLoanType()
	
	const {circularLoader} = props
	
	const newRowModel = {
		DescriptionEl: '',
		DescriptionEn: ''
	}
	
	const columns = useMemo(() => [
		{
			field: 'DescriptionEl',
			headerName: t('Description EL'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
		{
			field: 'DescriptionEn',
			headerName: t('Description EN'),
			minWidth: 160,
			flex: 1,
			editable: true,
			preProcessEditCellProps: (params) => {
				const inputValue = params.props.value;
				if (inputValue === '') {
					return {...params.props, error: t('Required')};
				}
				return {...params.props, error: null}; // No error
			},
			renderEditCell: (params) => {
				return <EditableMUICellValidation {...params}>
					<GridEditInputCell {...params} />
				</EditableMUICellValidation>
			},
		},
	], [loanType, t])
	
	return !loading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Loan Purpose')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper
				//autoHeight
				sx={{
					maxWidth: '700px',
					height: '500px'
				}}
			>
				<EditableMUIDataGrid
					sx={{maxHeight: 700}}
					loading={loading}
					data={loanType}
					columns={columns}
					newRowModel={newRowModel}
					update={update}
					hideDeleteButton={true}
					dataGridProOtherProps={{
						disableColumnMenu: true,
						slots: {
							toolbar: false,
							pagination: CustomPagination,
						}
					}}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={loading}
			useCircularLoader={circularLoader}
		/>
	)
}
export default withPermissionHOC(LoanType, ['access_parameters']);