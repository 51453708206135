// material-ui
import IconButton from "@mui/material/IconButton";
import { useTheme } from '@mui/material/styles';
import { ButtonBase, Stack, useMediaQuery } from '@mui/material';
import { Link } from "react-router-dom";
import MoreHorizontal from "../../../../../../assets/images/icons/menuIcons/moreHorizontal";
import MoreVertical from "../../../../../../assets/images/icons/menuIcons/moreVertical";
import { getRouteUrl } from "../../../../../../helpers/getRouteUrl";
import useMenuAction from "../../../../../../hooks/menu/useMenuAction";
import { useHasPermission } from "../../../../../../modules/permission/hooks/useHasPermission";
import { ROUTE_PAGE_HOME, ROUTE_PAGE_LANDING } from "../../../../../../routers/routes";

// project import
import DrawerHeaderStyled from './drawerHeaderStyled';
import useConfig from "../../../../hooks/useConfig";
import { MenuOrientation, ThemeMode } from "../../../../config";
import Logo from "../../../../components/logo";
import { Capacitor } from "@capacitor/core";
import LogoMain from "../../../../components/logo/logoMain";
import useMenuData from "../../../../../../hooks/menu/useMenuData";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
	const theme = useTheme();
	const downLG = useMediaQuery(theme.breakpoints.down('lg'));
	const { setDrawerOpen } = useMenuAction()
	const { menuOrientation } = useConfig();
	const { drawerOpen } = useMenuData()
	const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;
	const isPartner = useHasPermission(['access_partner_dashboard'], {shouldView: true})
	return (
		<DrawerHeaderStyled
			theme={theme}
			open={open}
			sx={{
				minHeight: isHorizontal ? 'unset' : '60px',
				width: isHorizontal ? { xs: '100%', lg: '424px' } : 'inherit',
				paddingTop: Capacitor.isNativePlatform() ? 3 : (isHorizontal ? { xs: '10px', lg: '0' } : '8px'),
				paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
				paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0,
				backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'inherit' : '#FFFFFF'
			}}
		>
			<Stack direction={'column'} spacing={3}>
				{!drawerOpen ?
					<IconButton onClick={() => {
						setDrawerOpen(true)
					}} sx={{ position: 'absolute', top: 0, right: 0 }}>
						<MoreHorizontal/>
					</IconButton>
					:
					<IconButton onClick={() => {
						setDrawerOpen(false)
					}} sx={{ position: 'absolute', top: 0, right: 0 }}>
						<MoreVertical/>
					</IconButton>
				}
				{!drawerOpen ?
					<Logo to={isPartner || Capacitor.isNativePlatform() ? getRouteUrl(ROUTE_PAGE_HOME) : getRouteUrl(ROUTE_PAGE_LANDING)} drawer={!open} sx={{ width: open ? 'auto' : 35, height: 35 }}/>
					:
					<ButtonBase
						disableRipple
						component={Link}
						to={isPartner || Capacitor.isNativePlatform() ? getRouteUrl(ROUTE_PAGE_HOME) : getRouteUrl(ROUTE_PAGE_LANDING)}
						onClick={(event) => {
							event.stopPropagation()
						}}
					>
						<LogoMain/>
					</ButtonBase>
				}
			</Stack>
		</DrawerHeaderStyled>
	);
};

// DrawerHeader.propTypes = {
//     open: PropTypes.bool
// };

export default DrawerHeader;
