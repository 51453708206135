import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import {ROUTES} from '../App'
import MenuWithDrawer from "../components/menuWithDrawer/menuWithDrawer";
import AuthorizedLanding from "../pages/authorizedLanding/authorizedLanding";
import AuthorizedWizard from "../pages/authorizedWizard/authorizedWizard";
import NotFound from "../pages/notFound/notFound";

const ContentRouter = () => {
	return (
		<Switch>
			{ROUTES.map(route => {
				const {key, path, exact, component} = route;
				const Component = component
				return (
					<Route
						key={key}
						path={path}
						exact={exact}
						render={() => (
							<MenuWithDrawer>
								<Component/>
							</MenuWithDrawer>
						)}
					/>
				)
			})}
			<Route path={'/landing'} exact component={AuthorizedLanding} />
			<Route path={'/wizard'} exact component={AuthorizedWizard} />
			<Redirect from={'/login'} to={'/'}/>
			<Route path={'/'}>
				<NotFound/>
			</Route>
		</Switch>
	)
}

export default ContentRouter