import React from "react";

import QuestionTitle from "../../elements/QuestionTitle";
import AddressField from "../../elements/AddressField";

const Area = () => {
  return (
    <>
      <QuestionTitle title="Property Location" main />
      <AddressField itemId="4" />
    </>
  );
}

export default Area;
