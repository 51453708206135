import React from "react";
import Stack from "@mui/material/Stack";

import { useWizard } from "../WizardContext";
import QuestionTitle from "../../elements/QuestionTitle";
import RadioOptions from "../../elements/RadioOptions";
import InfoMessage from "../../elements/InfoMessage";

const HaveProperty = () => {
  const { data, responses } = useWizard();

  return (
    <>
      <QuestionTitle title="Have you found a property?" main />
      <Stack spacing={3}>
        <RadioOptions
          itemId="2"
          options={data?.filter((data) => data.ItemId === "2")}
        />
        {responses["2"]?.AnswerId === "7" && (
          <InfoMessage
            message="Haven't found a property? IMS can help you find one through its extensive network of collaborating real estate agents."
          />
        )}
      </Stack>
    </>
  );
}

export default HaveProperty;
