import React, { useEffect, useState } from 'react'

const CountDownTimer = ({ minutes }) => {
  const initTime = minutes * 60;
  const [time, setTime] = useState(initTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        }

        return time - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <span style={{ fontVariantNumeric: "tabular-nums" }}>
      {`${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)}
    </span>
  );
}

export default CountDownTimer;
