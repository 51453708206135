import React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { WIZARD } from "../../../locales/namespaces";
import { useWizard } from "../WizardContext";
import useUserData from "../../../../hooks/user/useUserData";
import { intlNumber } from "../../../utils";

const MonthlyAmount = () => {
  const { t } = useTranslation(WIZARD);
  const { summary, responses } = useWizard();
  const { language } = useUserData();

  const loanAmount = Math.min(responses["10"].AnswerDescription, summary.max_amount);
  const loanAmountFormatted = intlNumber(loanAmount, language);

  return (
    <Stack
      spacing={2}
      sx={{
        p: 3,
        border: "1px solid #dddee1",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        boxShadow: "0px 2.94px 23.49px 0px #3D467026",
      }}
    >
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0655 11.7609H14.4988C13.1892 11.76 12.1278 10.6532 12.127 9.28668C12.127 7.92014 13.1892 6.81334 14.4988 6.8125H18.0655" stroke="#0BC1B6" strokeWidth="1.23293" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.9021 9.2285H14.6274" stroke="#0BC1B6" strokeWidth="1.23293" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.82647 1.2832H13.4416C15.995 1.2832 18.0649 3.44319 18.0649 6.10757V12.7057C18.0649 15.37 15.995 17.53 13.4416 17.53H5.82647C3.27311 17.53 1.20312 15.37 1.20312 12.7057V6.10757C1.20312 3.44319 3.27311 1.2832 5.82647 1.2832Z" stroke="#0BC1B6" strokeWidth="1.23293" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.19922 5.45506H9.95586" stroke="#0BC1B6" strokeWidth="1.23293" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <Typography sx={{ color: "#8F96A9" }}>
        {t("Indicative monthly installment for the loan amount")}:
        <br />
        <strong>{loanAmountFormatted}</strong>
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "30px", sm: "35px" },
          lineHeight: "46px",
        }}
      >
        {intlNumber(summary.monthly_amount, language)}
      </Typography>
    </Stack>
  );
}

export default MonthlyAmount;
