import React from "react"
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import { LANDING } from "../../locales/namespaces";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_WIZARD } from "../../../routers/routes";

const ApplyOnlineButton = () => {
  const { t } = useTranslation(LANDING);

  return (
    <Button
      variant="contained"
      color="info"
      component={Link}
      to={getRouteUrl(ROUTE_PAGE_WIZARD)}
      sx={{
        p: "15px 32px",
        fontWeight: "bold",
      }}
    >
      {t("Apply Now")}
    </Button>
  )
}

export default ApplyOnlineButton;
