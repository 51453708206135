// @flow
import React, { useEffect, useMemo } from 'react';
import {useMuiGridServerSide} from "../../../../hooks/useMuiGridServerSide";
import Box from "@mui/material/Box";
import {DataGridPremium} from '@mui/x-data-grid-premium';
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {PARAMETERS} from "../../locales/namespaces";
import DataGridWrapper from "../../../../components/dataGridWrapper/dataGridWrapper";
import {withPermissionHOC} from "../../../permission/components/permissionHOC/permissionHOC";
import {muiGridLocales} from "../../../../helpers/muiGridLocales";
import {Typography} from "@mui/material";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ContentLoader from "../../../../components/contentLoader/contentLoader";

type Props = {
	circularLoader?: boolean
}

const Documents = (props: Props) => {
	const {t} = useTranslation(PARAMETERS)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Documents')
	}, [t])
	
	const url = `document/indexTable/data`
	
	const {circularLoader} = props
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		initialSortModel: [{name: 'id', direction: 'descending'}]
	})
	
	const columns = useMemo(() => [
		{
			field: 'isActive',
			headerName: t('Active'),
			width: 90, maxWidth: 100, flex: 1,
			type: 'boolean',
			valueGetter: ({value}) => value === '1'
		},
		{
			field: 'Code',
			headerName: t('Document Code'),
			minWidth: 120, flex: 1,
		},
		{
			field: 'Description',
			headerName: t('Description'),
			minWidth: 200, flex: 1,
		},
		/*{
			field: 'OriginalFileName',
			headerName: t('Original File Name'),
			minWidth: 200, flex: 1,
		},*/
		{
			field: 'SavedFileName',
			headerName: t('File Name'),
			minWidth: 240, flex: 1,
		},
		{
			field: 'FileType',
			headerName: t('File Type'),
			minWidth: 140, flex: 1,
		},
		{
			field: 'Owner',
			headerName: t('Uploaded by'),
			minWidth: 300, flex: 1,
		},
	], [data, refreshGrid])
	
	return !isLoading ? (
		<MainCard
			title={
				<Typography variant="h5" gutterBottom>
					{t('Documents')}
				</Typography>
			}
			sx={{mt: 2}}
		>
			<DataGridWrapper>
				<DataGridPremium
					disableColumnMenu={true}
					sx={{
						bgcolor: 'background.paper', ".MuiDataGrid-aggregationColumnHeaderLabel": {
							display: "none"
						}
					}}
					columns={columns}
					density={'compact'}
					rows={data}
					pagination
					paginationModel={{page, pageSize}}
					paginationMode='server'
					onPaginationModelChange={onPaginationModelChange}
					rowCount={Number(total)}
					rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
					sortingMode="server"
					onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
					filterMode='server'
					onFilterModelChange={onFilterModelChange}
					loading={isLoading}
					localeText={muiGridLocales(t)}
				/>
			</DataGridWrapper>
		</MainCard>
	) : (
		<ContentLoader
			loading={isLoading}
			useCircularLoader={circularLoader}
		/>
	)
};

export default withPermissionHOC(Documents, ['access_parameters'])