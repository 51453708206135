import * as React from "react";

const MoreVertical = (props) => (
	<svg
		width={6}
		height={21}
		viewBox="0 0 6 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M1.966 18.586a1.31 1.31 0 1 0 2.62 0 1.31 1.31 0 0 0-2.62 0m0-7.862a1.31 1.31 0 1 0 2.62 0 1.31 1.31 0 0 0-2.62 0m0-7.862a1.31 1.31 0 1 0 2.62 0 1.31 1.31 0 0 0-2.62 0"
			fill="#C1C6D1"
		/>
		<path
			d="M1.966 18.586a1.31 1.31 0 1 0 2.62 0 1.31 1.31 0 0 0-2.62 0m0-7.862a1.31 1.31 0 1 0 2.62 0 1.31 1.31 0 0 0-2.62 0m0-7.862a1.31 1.31 0 1 0 2.62 0 1.31 1.31 0 0 0-2.62 0"
			stroke="#C1C6D1"
			strokeWidth={1.966}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default MoreVertical;
