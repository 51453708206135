import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import {
  Age,
  Area,
  Country,
  Demographics,
  Finance,
  HaveProperty,
  LoanAmount,
  Months,
  Purpose,
  Value,
  Discovery
} from "./Steps";
import { useWizard } from "./WizardContext";
import Pagination from "./Pagination";
import ValidationWarning from "../elements/ValidationWarning";

// The keys of stepsComponents are the "name" property in ALL_STEPS constant.
const stepsComponents = {
  "purpose": Purpose,
  "have_property": HaveProperty,
  "value": Value,
  "area": Area,
  "months": Months,
  "country": Country,
  "age": Age,
  "loan_amount": LoanAmount,
  "demographics": Demographics,
  "finance": Finance,
  "discovery": Discovery,
};

const MultiStepForm = () => {
  const { currentStep, steps, data } = useWizard();

  const CurrentStepComponent = stepsComponents[steps[currentStep].name];

  if (!CurrentStepComponent) return null;

  return (
    <Box sx={{ maxWidth: "324px", mx: "auto" }}>
      {data.length > 0 ? (
        <>
          <CurrentStepComponent />
          <Pagination />
          <ValidationWarning />
        </>
      ) : (
        <>
          {/* Question title */}
          <Skeleton />
          <Skeleton width="60%" sx={{ mx: "auto", mb: 8 }} />

          {/* Question answers */}
          {Array.from({ length: 5 }).map((_, i) => (
            <Skeleton key={i} height={56} />
          ))}

          {/* Pagination button */}
          <Skeleton width={109} height={34} sx={{ ml: "auto", mt: 8 }} />
        </>
      )}
    </Box>
  );
};

export default MultiStepForm;
