import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import { enqueueSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";

import PasswordIcon from "../../../../assets/images/icons/login/passwordIcon";
import { WIZARD } from "../../../locales/namespaces";
import { useWizard } from "../WizardContext";
import OTP from "../../elements/OtpField";
import { OTP_LENGTH } from "../constants";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import { textTranslate } from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";
import CountDownTimer from "../../elements/CountDownTimer";
import { getRouteUrl } from "../../../../helpers/getRouteUrl";
import { ROUTE_PAGE_PRIVACY_POLICY, ROUTE_PAGE_TERMS_CONDITIONS } from "../../../../routers/routes";
import XIcon from "../../../../assets/images/icons/xIcon";
import { intlNumber, resultMessageText } from "../../../utils";

const PasswordAdornment = () => (
  <InputAdornment position="start">
    <PasswordIcon />
  </InputAdornment>
);

const CtaForm = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  // Used to force re-render CountDownTimer
  const [countDownTrigger, setCountDownTrigger] = useState(0);

  const { language, isLoggedIn } = useUserData();
  const { t } = useTranslation(WIZARD);
  const { summary, validateUser, importUser, data, userData, responses } = useWizard();
  const phoneCodes = data.filter((item) => item.ItemId === "21");

  const enablePassword = !isLoggedIn && summary.approval_level > 1;
  const loanAmount = Math.min(responses["10"].AnswerDescription, summary.max_amount);

  const formik = useFormik({
    initialValues: {
      name: userData.name || "",
      email: userData.email || "",
      phone_code: userData.phonecode || "76",
      phone: userData.phone || "",
      password: "",
      confirm_password: "",
      accept_terms: false,
    },
    validationSchema: yup.object({
      name: yup.string().required(t("Required")),
      email: yup.string().email(t('Invalid email')).required(t("Required")),
      phone_code: yup.string().required(t("Required")),
      phone: yup
        .string()
        .required(t("Required"))
        .matches(/^\d+$/, t("Invalid phone number"))
        .test(
          "valid-length",
          t("Invalid phone number"),
          function (value) {
            return value ? value.length >= 8 && value.length <= 15 : true;
          }
        ),
      password: yup
        .string()
        .optional()
        .matches(
          /^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
          t("Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character"),
        ),
      confirm_password: yup.string().when(["password"], {
        is: (password) => Boolean(password),
        then: (schema) => schema
          .oneOf([yup.ref("password")], t("Passwords do not match"))
          .required(t("Required")),
      }),
      accept_terms: yup.boolean().oneOf([true], t("Required"))
    }),
    onSubmit: (values) => {
      if (!otp) {
        return validateUser({
          email: values.email,
          onSuccess: () => setShowOtp(true),
        });
      }

      const selectedPhoneCode = phoneCodes.find(
        (code) => code.AnswerId === values.phone_code
      );

      const items = [
        { "ItemId": "17", "ItemDescription": "Ονοματεπώνυμο", "AnswerId": "", "AnswerDescription": values.name },
        { "ItemId": "18", "ItemDescription": "Email", "AnswerId": "", "AnswerDescription": values.email },
        { "ItemId": "19", "ItemDescription": "Κινητό τηλέφωνο", "AnswerId": "", "AnswerDescription": values.phone },
        {
          "ItemId": "21",
          "ItemDescription": "Κωδικός τηλεφώνου",
          "AnswerId": selectedPhoneCode.AnswerId.toString(),
          "AnswerDescription": textTranslate(language)({
            el: selectedPhoneCode.AnswerDescriptionEl,
            en: selectedPhoneCode.AnswerDescriptionEn,
          }),
        },
        {
          "ItemId": "23",
          "ItemDescription": "Το μέγιστο ποσό που μπορείτε να δανειστείτε βάσει των στοιχείων που μας δώσατε",
          "AnswerId": "",
          "AnswerDescription": intlNumber(summary.max_amount)
        },
        {
          "ItemId": "24",
          "ItemDescription": `Ενδεικτική μηνιαία δόση για ποσό δανείου: ${intlNumber(loanAmount)}`,
          "AnswerId": "",
          "AnswerDescription": intlNumber(summary.monthly_amount)
        },
        {
          "ItemId": "25",
          "ItemDescription": "Επίπεδο βιωσιμότητας",
          "AnswerId": "",
          "AnswerDescription": t(resultMessageText({
            level: summary.approval_level,
            resultingPrefix: summary.max_amount < responses["10"].AnswerDescription
          }))
        }
      ];

      if (enablePassword) {
        items.push({
          "ItemId": "20",
          "ItemDescription": "Κωδικός",
          "AnswerId": "",
          "AnswerDescription": values.password,
        });
      }

      return importUser({ items, code: otp });
    }
  });

  const handleResend = () => {
    validateUser({
      email: formik.values.email,
      onSuccess: () => {
        setOtp("");
        setCountDownTrigger((prev) => prev + 1);

        enqueueSnackbar(
          t("A new code has been sent in your email"),
          { variant: "success" }
        )
      },
    });
  }

  return (
    <FormikProvider value={formik}>
      <Stack component="form" spacing={2} onSubmit={formik.handleSubmit}>
        <FormControl sx={{ gap: 1 }}>
          <Typography component={FormLabel} htmlFor="wizard.name">
            {t("Full name")}
          </Typography>
          <TextField
            id="wizard.name"
            name="name"
            sx={{ borderRadius: "8px" }}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            disabled={Boolean(userData.name)}
          />
        </FormControl>
        <FormControl sx={{ gap: 1 }}>
          <Typography component={FormLabel} htmlFor="wizard.email">
            {t("Email")}
          </Typography>
          <TextField
            type="email"
            id="wizard.email"
            name="email"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={Boolean(userData.email)}
          />
        </FormControl>
        <Typography>
          {t("Mobile phone")}
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }} style={{ marginTop: "8px" }}>
          <FormControl>
            <Select
              id="wizard.phone_code"
              name="phone_code"
              size="small"
              IconComponent={ArrowDown2}
              value={formik.values.phone_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={Boolean(userData.phonecode)}
              sx={{
                py: 1,
                px: 0.5,
                ".MuiSelect-icon": {
                  right: "14px",
                }
              }}
            >
              {phoneCodes.map((option) => {
                const { AnswerId, AnswerDescriptionEl, AnswerDescriptionEn } = option;
                const answerDescription = textTranslate(language)({
                  el: AnswerDescriptionEl,
                  en: AnswerDescriptionEn,
                });

                return (
                  <MenuItem key={AnswerId} value={AnswerId}>
                    <Typography>
                      {answerDescription}
                    </Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: "1", gap: 1 }}>
            <TextField
              type="tel"
              id="wizard.phone"
              name="phone"
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              disabled={Boolean(userData.phone)}
            />
          </FormControl>
        </Box>
        {enablePassword && (
          <>
            <Typography sx={{ py: 2 }}>
              {t("By setting a password, you can create an account to track your application")}
            </Typography>
            <FormControl sx={{ gap: 1 }} >
              <Typography component={FormLabel} htmlFor="wizard.password">
                {t("Create a password")}
              </Typography>
              <TextField
                type="password"
                id="wizard.password"
                name="password"
                InputProps={{
                  startAdornment: <PasswordAdornment />,
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password
                  && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
            <FormControl sx={{ gap: 1 }}>
              <Typography component={FormLabel} htmlFor="wizard.confirm_code">
                {t("Confirm password")}
              </Typography>
              <TextField
                type="password"
                id="wizard.confirm_code"
                name="confirm_password"
                InputProps={{
                  startAdornment: <PasswordAdornment />,
                }}
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirm_password
                  && Boolean(formik.errors.confirm_password)
                }
                helperText={
                  formik.touched.confirm_password
                  && formik.errors.confirm_password
                }
              />
            </FormControl>
          </>
        )}
        <FormControl>
          <FormControlLabel
            label={
              <>
                {t("I accept the")}{" "}
                <Link
                  component={RouterLink}
                  color="textPrimary"
                  to={getRouteUrl(ROUTE_PAGE_PRIVACY_POLICY)}
                  underline="always"
                  target="_blank"
                >
                  {t('Privacy Policy')}
                </Link>{" "}
                {t("and the")}{" "}
                <Link
                  component={RouterLink}
                  color="textPrimary"
                  to={getRouteUrl(ROUTE_PAGE_TERMS_CONDITIONS)}
                  underline="always"
                  target="_blank"
                >
                  {t('Terms & Conditions')}
                </Link>
              </>
            }
            labelPlacement="end"
            control={
              <Checkbox
                size="medium"
                name="accept_terms"
                checked={formik.values.accept_terms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            error={
              formik.touched.accept_terms
              && Boolean(formik.errors.accept_terms)
            }
          />
          <FormHelperText error>
            <ErrorMessage name={"accept_terms"} />
          </FormHelperText>
        </FormControl>
        <div>
          <LoadingButton
            type="submit"
            variant="contained"
            color="info"
            sx={{
              p: "12px 32px",
              ".MuiLoadingButton-loadingIndicator": {
                color: "#fff",
              }
            }}
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            {t("Submit")}
          </LoadingButton>
        </div>
      </Stack>
      <Dialog open={showOtp}>
        <IconButton
          aria-label="Close"
          onClick={() => setShowOtp(false)}
          sx={() => ({
            m: 1,
            marginLeft: "auto",
            "& path": {
              stroke: "#000000"
            }
          })}
        >
          <XIcon />
        </IconButton>
        <DialogContent sx={{
          p: { xs: 4, sm: 8 },
          pt: { xs: 0, sm: 0 },
          pb: 4,
          textAlign: "center"
        }}>
          <DialogContentText
            sx={{
              mb: 4,
              fontSize: "18px",
              lineHeight: "24px",
              fontWeight: "600",
            }}
          >
            {t("Enter the code you received in your email")}
          </DialogContentText>
          <OTP value={otp} onChange={setOtp} length={OTP_LENGTH} />
          <DialogContentText sx={{ mt: 2 }}>
            {t("The code will be active for")}{" "}
            <CountDownTimer key={countDownTrigger} minutes={5} />
          </DialogContentText>
          <Button
            sx={{ fontSize: "14px", textDecoration: "underline" }}
            onClick={handleResend}
          >
            {t("Resend")}
          </Button>
          <Button
            variant="contained"
            color="info"
            sx={{ display: "block", mx: "auto", mt: 3, p: "12px 32px" }}
            onClick={formik.handleSubmit}
          >
            {t("Submit")}
          </Button>
        </DialogContent>
      </Dialog>
    </FormikProvider>
  );
}

export default CtaForm;
