import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { WIZARD } from "../../locales/namespaces";

const QuestionTitle = ({ title, helperText, main }) => {
  const { t } = useTranslation(WIZARD);

  return (
    <Box mb={main ? 8 : 4} align={main ? "center" : "inherit"}>
      <Typography
        component="h2"
        variant="h4"
        sx={{
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: { sm: 500 }
        }}
      >
        {t(title)}
      </Typography>
      {helperText && (
        <Typography mt={1} sx={{ opacity: 0.8 }}>{helperText}</Typography>
      )}
    </Box>
  );
}

export default QuestionTitle;
