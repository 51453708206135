import React, { useEffect } from "react";
import {Box, Typography, List, ListItem, ListItemText, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import MainCard from "../../theme/mantis/components/mainCard";

const TermsConditions = () => {
	const {t} = useTranslation(PAGE_LOGIN)
	
	useEffect(() => {
		document.title = t('IMS Financial Consulting | Terms & Conditions')
	}, [t])
	
	return (
		<Box>
			<MainCard
				title={
					<Typography variant="h4">
						{t('IMS Platform Terms and Conditions of Use')}
					</Typography>
				}
				sx={{
					mt: 2
				}}
			>
				<Typography
					variant="h5"
					sx={{mb: 1}}
				>
					{t('Your use of the IMS Platform constitutes your acceptance of these Terms and Conditions.')}
				</Typography>
				<Typography
					variant="h5"
					sx={{
						textDecoration: 'underline',
						mb: 1
					}}
				>
					{t('Please ensure that you have read these Terms and Conditions carefully.')}
				</Typography>
				<Typography variant="h5" sx={{mb: 1}}>
					{t('The IMS Platform Services are provided to you subject to the following terms and conditions. By accessing or using our platform, you agree to be bound by these terms and conditions of use.')}
				</Typography>
				<Typography variant="h5" sx={{mb: 1}}>
					{t('The use of the platform is governed by these terms and conditions, which users and/or visitors are requested to read carefully and comply with. The use of the platform is the sole responsibility of the visitor - user and implies full, unconditional acceptance and agreement on his part of the following terms and conditions of use, as well as the terms of the personal data protection policy.')}
				</Typography>
				<Typography variant="h5" sx={{mb: 1}}>
					{t('The user undertakes that the information he provides to IMS is true, accurate, valid and complete, he is responsible for all operations carried out with the access code he has after registration. In addition, he is obliged to inform IMS immediately in the event of unauthorized use or security breach, and bears full responsibility for any damage caused by the possible inaccuracy of this information or by the arbitrary or illegal use of the password.')}
				</Typography>
				<Typography variant="h5" sx={{mb: 1}}>
					{t('The user is solely responsible for the content of any document placed on the Platform and for any consequence that may arise from the placement of this document. Any content offensive to the honor, reputation, personality, personal data of third parties that results in any damage or moral harm, the person responsible for the restoration will be exclusively himself, that is, the user.')}
				</Typography>
				<Typography variant="h5" sx={{mb: 1}}>
					{t('IMS reserves the right to remove material and/or document, which is in conflict with the terms of use of the Platform and/or with the existing legislation and commercial ethics, informing the person who made the entry, immediately, in whole or in part.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('In the event that registered members of IMS make illegal use of the Platform, IMS has the right to immediately delete them from the list of registered members and prohibit their access to it, reserving for any legal right it has from the above illegal use.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('The information provided - completed by the visitor-user to serve his request may be shared in whole or in part only at the absolute discretion of the Platform administrators and only to the directly interested party or to a person who has a legal interest and/or to the competent authority or authorities as the case may be, as well as to any other person or authority to whom such notification may be deemed due based on relevant provisions or based on a court or authority decision. In any of these cases all or any information of the visitor - user as well as his IP address may be part of any investigation, if this is required to protect the rights of IMS or to protect the rights of third parties being violated or in general for the stop or prevent any infringement.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('This data or any part of it may be archived or used by IMS without any claim or demand for any reason against IMS by the visitor-user who provides it or generally provides access to it, since the very provision of access to Platform from its administrators constitutes sufficient and full consideration for the provision of this information by the user - visitor to the website.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('Each visitor-user is fully responsible for the use in any way or medium and at all times of the IMS Platform or any part of it or any element of its content and may use its services only for legal and legitimate purposes and in general in a manner and means that are consistent with and do not violate the applicable legal rules and regulations and the general principles of good faith and good morals.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('You must not use the Platform in a way that could cause any form of injury, damage or harm or destroy, disable or damage our servers or networks or any part thereof, or in ways that interfere with or impede access , use and exploitation of the services or content of the Platform or any part thereof by any other member-visitor-user or third party.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('It is prohibited for the visitor - user to create false accounts with fraudulent, illegal or unfair intentions in general, or to collect or store personal data of other users in any way and for any purpose at any time or to assist others in any of the above purposes and in general to take any action similar to the above even if not expressly mentioned herein.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 2}}>
					{t('The interception of information as well as its republishing or sharing with any third party, if it is carried out without the prior written consent - consent of IMS is an illegal act for which the interceptor is solely responsible, who will face the civil penalties provided for by law and criminal penalties.')}
				</Typography>
				<Typography
					gutterBottom
					variant="body"
					fontWeight={700}
					component="div"
					sx={{mb: 2}}
				>
					{t('ONLINE DISCLAIMER AND LIMITATION OF LIABILITY')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('The IMS platform is provided to users/visitors "as is", and although every effort is made for the accuracy and completeness of all its content, it does not provide any kind of guarantee for this, nor is it responsible for any damage that may be caused to the user /visitor from its use.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('The services provided by the IMS Platform do not establish any agency or employment relationship.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('IMS makes every possible effort, in the context of technological control, in order for the services to be provided seamlessly and without interruption. However, it bears no responsibility in the event that for any reason, including the case of negligence, its operation is interrupted or access to it becomes difficult and/or impossible and/or, despite the observed security measures, "viruses" or other harmful software and transmitted to the terminals of users/visitors, or if unauthorized third parties (hackers) interfere with its content and operation, making it difficult to use or causing problems in its proper operation.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('IMS reserves the exclusive right, and the user/visitor accepts this, to permanently or temporarily stop the operation of the Platform with or without notice to the users/members.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('By registering a person as a member of the IMS Platform, we reserve the right to send them informational or advertising material, which belongs to partner companies, without warning, as well as to allow their exposure in an advertising communication or campaign hosted on the IMS websites.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('The visitor - user is solely responsible for the safe and unobstructed access to his personal accounts on the Platform, as well as for ensuring the protection and security of his access codes to the website and the exclusion of unauthorized persons from them. He is solely responsible for the control, use, management and access to activation codes as well as access codes and for blocking access to them by third parties not authorized by them.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 2}}>
					{t('reserves_the_right')}
				</Typography>
				<Typography
					gutterBottom
					variant="body"
					fontWeight={700}
					component="div"
					sx={{mb: 2}}
				>
					{t('COPYRIGHT')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 2}}>
					{t('The overall content of the Platform, including but not limited to images, graphics, photos, plans, used software, prototype applications, texts, services provided, material layout, overall design and presentation, marks, distinguishing features and generally all files of this website, are the exclusive intellectual and industrial property of IMS and are protected by the relevant provisions of Greek, Community and international law. Therefore, none of them may in principle be sold, transferred, licensed and exploited, copied, modified, reproduced, republished, processed or "uploaded", transmitted or distributed in any way to other faces. Otherwise, the violators will be prosecuted, IMS reserving against everything for any legal or contractual right it has from the illegal use of the Platform.')}
				</Typography>
				<Typography
					gutterBottom
					variant="body"
					fontWeight={700}
					component="div"
					sx={{mb: 2}}
				>
					{t('PERSONAL DATA PROTECTION')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('1. IMS will use the above information in order to improve its services on the one hand and to communicate with users/visitors/members on the other.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('2. IMS does not have the right to sell and/or disclose personal data to third parties without the express consent of users.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('3. In no case does this cover the relationship between visitors/users/members from the website and any services that are not under the control/ownership of IMS. IMS collects personal data when the visiting user registers on its platform.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('four_personal_data')}
				</Typography>
				<Typography variant="body2" color="text.secondary" style={{fontSize: '1rem'}}
							component={'div'}>
					<List
						sx={{
							listStyleType: 'disc',
							pl: 2,
							'& .MuiListItem-root': {
								display: 'list-item',
								color: '#485776'
							},
							ml: 1
						}}
					>
						<ListItem disablePadding>
							<ListItemText
								primary={t('the fulfillment of the terms and obligations of the individual services provided by the Platform to its users/visitors,')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText primary={t('informing the user in general,')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('the satisfaction of the user\'s requirements in relation to the services available,')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('any action related to the above.')}/>
						</ListItem>
						<ListItem disablePadding>
							<ListItemText
								primary={t('Any possible future change in the legislative regulation of personal data will automatically and irrevocably apply to the management and protection of the personal data of the users/visitors of this website. The personal information of the users is not disclosed to third parties, with the exception of where provided by the Law and only to the competent authorities, and always following the procedure provided for by the law.')}/>
						</ListItem>
					</List>
				</Typography>
				<Typography variant={"h5"} sx={{mb: 2}}>
					<b>{t('We use any personal information you provide to us through your use of the Platform and Service in the ways set forth in our Privacy Policy. ')}</b>
					{t('We do not use this data in any other way. If you download the Platform from the App Store or Google Play, the terms and conditions and privacy policy of that App Store or Google Play may apply to you. We encourage you to read the terms and conditions and privacy policy of the relevant App Store or Google Play you use to download the Platform to understand their terms and how they use your personal information.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1, fontWeight: 'bold'}}>
					{t('Updates to the Platform and changes to the Service')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('From time to time we may automatically update or change the Platform and/or Service to improve performance, upgrade functionality, reflect operating system changes, or address security issues. Alternatively we may ask you to update the Platform yourself for these reasons.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('If you choose not to install such updates or if you opt out of automatic updates, you may not be able to continue using the Platform and the Service.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1, fontWeight: 'bold'}}>
					{t('Termination')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('If you violate these Terms and Conditions, you will lose your ability to use the Platform and/or the Service. These actions are in addition to any other right or remedy that IMS may have.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('You may terminate these Terms and Conditions by ceasing to access and use the Platform. We may terminate these Terms and Conditions at any time without notice for any or no reason.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1, fontWeight: 'bold'}}>
					{t('after_termination')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('When all rights granted to you under these Terms and Conditions are terminated, you must stop using the Platform entirely and delete the application from your mobile device. IMS will remove your access rights to the Platform.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('Termination shall not limit any of IMS\'s rights or remedies at law or in equity.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('Users undertake that the information they provide to the Platform is true, accurate, valid and complete, they are responsible for all operations carried out with the access code they have after registration. In addition, they are obliged to inform IMS immediately, in case of unauthorized use or security breach, and bear full responsibility for any damage caused by the possible inaccuracy of this information or by the arbitrary or illegal use of the password.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					<b>{t('Platform Security. ')}</b>{t('You understand and agree that the Platform, like most other software and services on the Internet, may be subject to security issues (including without limitation security issues caused by users, quality of network services, social environment, viruses, programs Trojans, malware, etc.). IMS is not responsible for and cannot ensure the security of any data transmission by you or your mobile device due to the use of unsecured internet connections. You accept the inherent consequences of using the Platform and you are advised to adopt security measures to protect your device (such as up-to-date anti-virus systems).')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					<b>{t('Compensation. ')}</b>{t('You agree to indemnify, defend and hold harmless IMS, its officers, directors, employees, agents, affiliates, successors and assigns from and against any loss, damage, liability, deficiency, claim, action , judgments, settlements, interests, awards, penalties, fines, costs or expenses of any kind, including reasonable attorneys\' fees, arising out of or related to your use or misuse of the Platform or Service or your violation of these Terms and Conditions, including and not just the content you submit or make available through this Platform.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					<b>{t('Separation. ')}</b>{t('If any provision of these Terms and Conditions is illegal or unenforceable under applicable law, the remainder of the provision will be modified so as to achieve as nearly as possible the effect of the original clause and all other provisions of such Terms and Conditions, so that they continue to remain in full force and produce results.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					<b>{t('Applicable Law. ')}</b>{t('These Terms and Conditions of Use and any amendment thereof are governed exclusively by Greek Law. For any dispute that may arise from this contract or from the execution or interpretation of it or any part of it, the courts of Athens, of any degree and jurisdiction, are defined as exclusively competent, even in relation to injunctive relief procedures, to which any user is expressly subject – a visitor or other person who comes into contact in any way and for any reason with the website or its content, even when the Platform is accessed from a country outside of Greece. If any of the above conditions were to be declared invalid or unenforceable by a court decision or decision of a competent authority, this does not affect the validity and effectiveness of the remaining conditions, which remain in full force and are still fully valid.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					<b>{t('Entire Agreement. ')}</b> {t('These Terms and Conditions and our Privacy Policy constitute the entire agreement between you and IMS with respect to the Platform and Service and supersede all prior or existing agreements, whether written or oral.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					<b>{t('Resignation. ')}</b>{t('No failure or delay in the exercise, by any party, of any right or any power shall operate as a waiver, nor shall it constitute a partial or non-exercise of any right or power that precludes further exercise thereof. In the event of a conflict between these Terms and Conditions and any applicable purchasing or other terms, the terms of these Terms and Conditions shall prevail.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					<b>{t('Update on the terms of the contract. ')}</b>{t('These Terms and Conditions may be updated from time to time. By continuing to use the Platform, you agree to accept all updated Terms. If you do not agree to any of the updated Terms and Conditions, you must stop using the Platform.')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1, fontWeight: 'bold'}}>
					{t('Contact us')}
				</Typography>
				<Typography variant={"h5"} sx={{mb: 1}}>
					{t('contact_with_us')}
					<Button sx={{textDecoration: 'underline'}}
							onClick={() => window.location = 'mailto:support@ims-fc.gr'}>{t('support@ims-fc.gr')}</Button>
				</Typography>
			</MainCard>
		</Box>
	)
}

export default TermsConditions